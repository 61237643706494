'use strict';

var app = angular.module('limaApp');

app.factory('Auth', ['$location', '$rootScope', '$http', 'User', '$cookies', '$q', '$timeout', 'socket',
function Auth($location, $rootScope, $http, User, $cookies, $q, $timeout, socket) {
  var currentUser = {};
  if ($cookies.get('token_admin')) {
    currentUser = User.get();
  }

  var promises = {};

  socket.on('system-properties:changed', function () {
    delete currentUser.systemProperties;
  });

  return {

    /**
     * Authenticate user and save token
     *
     * @param  {Object}   user     - login info
     * @param  {Function} callback - optional
     * @return {Promise}
     */
    login: function(user, callback) {
      callback = callback || angular.noop;
      var deferred = $q.defer();

      $http.post('/auth/local', {
        loginAdmin:user.loginAdmin,
        companyLoginId: user.companyLoginId,
        email: user.email,
        password: user.password
      }).then(function (res) {
        socket.disconnect();
        // $cookies.put('token', res.data.token, {path:'/', domain:$location.host()});
        $cookies.put('token_admin', res.data.token, {path:'/', domain:$location.host()});
        currentUser = User.get(function () {
          deferred.resolve(res.data);
        }, function (err) {
          deferred.reject(err);
        });
        callback();
      }, function (err) {
        this.logout();
        deferred.reject(err.data);
        callback(err.data);
      }.bind(this));

      return deferred.promise;
    },

    /**
     * Delete access token and user info
     *
     * @param  {Function}
     */
    logout: function() {
      socket.disconnect();
      //$cookies.remove('token', {path:'/', domain:$location.host()});
      $cookies.remove('token_admin', {path:'/', domain:$location.host()});
      currentUser = {};
    },

    /**
     * Change password
     *
     * @param  {String}   oldPassword
     * @param  {String}   newPassword
     * @param  {Function} callback    - optional
     * @return {Promise}
     */
    changePassword: function(oldPassword, newPassword, callback) {
      callback = callback || angular.noop;
      var deferred = $q.defer();

      User.changePassword({id:currentUser._id}, {
        oldPassword: oldPassword,
        newPassword: newPassword
      }, function(data) {
        var token = data.token;
        //$cookies.put('token', data.token, {path:'/', domain:$location.host()});
        $cookies.put('token_admin', data.token, {path:'/', domain:$location.host()});
        currentUser = User.get(function () {
          deferred.resolve(data);
        }, function (err) {
          deferred.reject(err);
        });
        callback(data);
      }, function(err) {
        deferred.reject(err);
        callback(err);
      });

      return deferred.promise;
    },

    /**
     * Gets all available info on authenticated user
     *
     * @return {Object} user
     */
    getCurrentUser: function() {
      return currentUser;
    },

    /**
     * Check if a user is logged in
     *
     * @return {Boolean}
     */
    isLoggedIn: function() {
      if (!currentUser.hasOwnProperty('roles'))
        return false;
      return true;
    },

    /**
     * Waits for currentUser to resolve before checking if user is logged in
     */
    isLoggedInAsync: function(callback) {
      if (currentUser.hasOwnProperty('$promise')) {
        currentUser.$promise.then(function() {
          callback(true);
        }, function() {
          callback(false);
        });
      } else if(currentUser.hasOwnProperty('roles')) {
        callback(true);
      } else {
        callback(false);
      }
    },

    /**
     * Check if a user is an admin
     *
     * @return {Boolean}
     */
    isAdmin: function() {
      if (!currentUser.roles || !currentUser.roles instanceof Array) return false;
      return (currentUser.roles.indexOf('administrator') >= 0);
    },

    /**
     * Get auth token
     */
    getToken: function() {
      return $cookies.get('token_admin');
    },

    /**
     * Check has function
     */
    hasFunction: function(functionName) {
      if (!currentUser.functions || !currentUser.functions instanceof Array) return false;
      return currentUser.functions.indexOf(functionName) >= 0;
    },

    updateCurrentUser: function () {
      var deferred = $q.defer();
      currentUser = User.get(function () {
        deferred.resolve();
      }, function (err) {
        deferred.reject();
      });
      return deferred.promise;
    },

    systemProperties: function (options) {
      options = _.assign({clear:false, refresh:false}, options);
      var deferred = $q.defer();
      if (options.clear) {
        var tmp = currentUser.systemProperties;
        delete currentUser.systemProperties;
        $timeout(function () { deferred.resolve(tmp); }, 0);
        return deferred.promise;
      }
      if (!options.refresh && currentUser.systemProperties) {
        $timeout(function () { deferred.resolve(_.cloneDeep(currentUser.systemProperties)); }, 0);
        return deferred.promise;
      }
      delete currentUser.systemProperties;
      if (_.isArray(promises.systemProperties)) {
        promises.systemProperties.push(deferred);
        return deferred.promise;
      }
      promises.systemProperties = [deferred];
      var cu = currentUser;
      function resolve(data) {
        var deferreds = promises.systemProperties;
        delete promises.systemProperties;
        if (cu !== currentUser) {
          _.forEach(deferreds, function (deferred) {
            deferred.reject('logged out');
          });
        } else {
          currentUser.systemProperties = {};
          _.forEach(data, function (d) {
            try {
              currentUser.systemProperties[d.name] = angular.fromJson(d.value);
            } catch (ex) {
              currentUser.systemProperties[d.name] = d.value;
            }
          });
          (function (styleId) {
            if (!styleId) return;
            var $styles = angular.element('.lima-bootstrap-style');
            styleId = '#' + styleId;
            if (!$styles.filter(styleId).length) return;
            $styles.filter(styleId).prop('disabled', false);
            $styles.not(styleId).prop('disabled', true);
          })(currentUser.systemProperties['setting.style.id']);
          _.forEach(deferreds, function (deferred) {
            deferred.resolve(_.cloneDeep(currentUser.systemProperties));
          });
        }
      }
      function reject(err) {
        var deferreds = promises.systemProperties;
        delete promises.systemProperties;
        _.forEach(deferreds, function (deferred) {
          deferred.reject(err);
        });
      }
      socket.connect().then(function () {
        return socket.call('v1:system:system-property:findAll', {}, 1000);
      }).then(function (res) {
        if (res) resolve(res);
      }, function (err) {
        return $http.get('/api/v1/system/');
      }).then(function (res) {
        if (res) resolve(res.data);
      }, function (err) {
        reject(err);
      });
      return deferred.promise;
    }
  };

}]);
