'use strict';

var app = angular.module('limaApp');

app.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('adminLogin', {
    url: '/',
    templateUrl: 'app/company/admin/adminLogin.html',
    authenticate: false,
    controller: 'AdminLoginCtl',
    title: '管理サーバ'
  })
}]);
