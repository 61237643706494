'use strict';

var app = angular.module('limaApp');


app.controller('CompanyOptionCtrl', ['$scope', 'socket', 'Auth','$http', '$timeout', '$location','$cookies','$uibModalInstance','$uibModal', 'CompanyInfo','MediaedgeAdmin',
function($scope, socket, Auth, $http, $timeout, $location, $cookieStore,$modalInstance,$modal,CompanyInfo, MediaedgeAdmin) {

  //- ===========================
  //-  オプションテーブル関連。pugと連携するものはここに定義
  const _selectOptionLibrary = require('../_selectOptionLibrary');
  $scope.optlib = new _selectOptionLibrary($scope);
  $scope.optlib.MediaedgeAdmin = MediaedgeAdmin;
  $scope.optionServiceList= undefined;
  $scope.totalPrices = 0;       // 費用合計
  $scope.initialPrices = $scope.optlib.getInitialPrices();  // 契約手数料
  $scope.changeDeviceChoise = function (optName, optChecked) { return $scope.optlib.changeDeviceChoise($scope, optName, optChecked);  }
  $scope.getPackageType = function() { return CompanyInfo.data.packageType; }
  //- ===========================
  $scope.optlib.getListOfOptionServiceFromCompanyId( $scope, $http, $timeout, 
              CompanyInfo.token, 
              CompanyInfo.data.companyId, 
              CompanyInfo.data.packageType, function() {
  });

  $timeout(function() {
    $scope.companyInfo = CompanyInfo.data;

  });
  $scope.cancel = function() {
    //$modalInstance.dismiss('cancel');

  }
  $scope.apply = function () {
    $modalInstance.close({name:$scope.formInput.inputname});
    $modalInstance.dismiss('done');
    //$dismiss();
  }
  $scope.cancel = function() {
    $modalInstance.dismiss('cancel');

  }

  $scope.changeOption = function(companyData) {
    var modalInstance = $modal.open({
      templateUrl: 'app/company/admin/editOption.html',
      controller: 'CompanyEditOptionCtrl',
      //windowClass: 'modal-rename-window',
      backdrop: 'static', keyboard: false,
      windowClass: 'modal-fit',
       //size: 'lg',
      resolve: {
        CompanyInfo: function() {
          return {
            data : CompanyInfo.data,
            token: CompanyInfo.token
          };
        }
      }
    });
    modalInstance.result.then(function(results) {
      //console.log("111");

    }, function() {
      //
      console.log("222");
      $timeout(function(){
        //再読み込み
        $scope.optlib.getListOfOptionServiceFromCompanyId( $scope, $http, $timeout, 
            CompanyInfo.token, 
            CompanyInfo.data.companyId, 
            CompanyInfo.data.packageType, function() {
        });

      });
    });

}

}]);
