'use strict';

var app = angular.module('limaApp');

app.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('system', {
    url: '/company/system',
    templateUrl: 'app/system/system.html',
    controller: 'SystemCtrl',
    authenticate: false,
    //functionName: 'SystemManagement',
    title: 'System Settings'
  });
}]);
