'use strict';

var app = angular.module('limaApp');

app.controller('CompanySupportListCtrl', ['$scope', '$location', 'Auth', '$http', '$window', '$timeout', '$uibModal', 'MediaedgeAdmin', 'LimaRedirect',
  function ($scope, $location, Auth, $http, $window, $timeout, $modal, MediaedgeAdmin, LimaRedirect) {
    $scope.user = {};

    // メディアエッジ社内向けページでは、registIdがSessionStrageに設定されていること（電話番号を暗号化して使用）
    MediaedgeAdmin.checkRegistId($http, function (can, tokenResult) {
      if (!can) {
        $window.location.href = "/admin";
      }
      else {
        $scope.encToken = encodeURIComponent(tokenResult.token);
        $scope.user = tokenResult.user;
        $scope.getdbTimezone();
      }
    });

    $scope.pageType = 'companyList';
    $scope.companyList = [];
    const showNumOnePage = 12; //1ページに表示する件数
    $scope.totalPageCount = 1; //全ページ数
    $scope.currentPage = 0;   　//現在の表示ページ(0始まり)
    $scope.totalCompanyCount = 0;　//全件数雨

    $scope.dbtimezoneString = "";
    $scope.getdbTimezone = function () {
      $http.get('/api/v1/system-info/db').
        success(function (datas) {
          console.log(datas);
          _.find(datas, function (dt) {
            if (dt.Variable_name === 'system_time_zone') {
              $scope.dbtimezoneString = dt.Value;
              return true;
            }
          });
          InitInfo();
        });
    }

    function InitInfo() {
      $http({
        url: '/api/v1/company/',
        method: 'GET',
      })
        .success(function (result) {
          var offset = 0;
          var limit = 0;
          $scope.currentPage = 0;
          //      $scope.totalCompanyCount=result.count;
          $scope.totalCompanyCount = result.length;
          if (result.length > showNumOnePage) {
            $scope.totalPageCount = Math.ceil(result.length / showNumOnePage)
            limit = showNumOnePage;
          }
          else {
            $scope.totalPageCount = 1;
            limit = result.length;
          }
          getCompanyInfoList(offset, limit, function (data) {
            if (data != null) {
              $timeout(function () {
                if ($scope.dbtimezoneString === 'UTC') {  //S3 AURORA
                  $scope.companyList = _.forEach(data, function (list) {
                    //UTC->SYSTEM
                    const defaultTimezoneOffset = new Date().getTimezoneOffset() * -1;
                    var dt = new Date(list.createTime);
                    dt.setMinutes(dt.getMinutes() + defaultTimezoneOffset);
                    list.createTime = dt;
                  });
                } else {
                  $scope.companyList = data;
                }
              });
            }
          });
        })
    }




    $scope.regInfo = null;
    MediaedgeAdmin.getLoginInfo($http, function (err, info) {
      $scope.regInfo = info;

    });

    $scope.updateInfo = function (page) {
      var offset = 0;
      var limit = showNumOnePage;
      if ($scope.totalCompanyCount <= showNumOnePage) {
        return;
      }
      $scope.currentPage += page;
      if (page < 0) {
        if ($scope.currentPage < 0) {
          $scope.currentPage = 0
          offset = 0;
          limit = showNumOnePage;
        }
        else {
          offset = $scope.currentPage * showNumOnePage;
          limit = showNumOnePage;
        }
      }
      else {
        if ($scope.currentPage >= $scope.totalPageCount) {
          $scope.currentPage = $scope.totalPageCount - 1;
          offset = $scope.currentPage * showNumOnePage;
          limit = totalCompanyCount - offset;
        }
        else {
          offset = $scope.currentPage * showNumOnePage;
          limit = showNumOnePage;
        }
      }
      getCompanyInfoList(offset, limit, function (data) {
        if (data != null) {
          $timeout(function () {
            $scope.companyList = data;
          });
        }
      });
    }
    /*
     *
     */
    function getCompanyInfoList(offset, limit, callback) {
      const url = $scope.regInfo.config.setting.protocol
        + "://"
        + $scope.regInfo.config.setting.domain
        + ":"
        + $scope.regInfo.config.setting.port
        + '/api/v1/company/list' + "?access_token=" + $scope.regInfo.token;

      $.ajax({
        type: 'GET',
        url: url,
        dataType: 'json',
        processData: false,
        data: $.param({
          offset: offset,
          limit: limit
        }),
        success: function (data) {
          callback(data);
        },
        error: function (data) {
          callback(null);
        }
      });

    }

    $scope.registNewCompany = function () {
      $window.location.href = "/company/register";
    }

    /*
     *
     */
    $scope.showCompanyInfo = function (companyData) {
      var modalInstance = $modal.open({
        templateUrl: 'app/company/admin/info.html',
        controller: 'CompanyInfoCtrl',
        windowClass: 'modal-rename-window',
        backdrop: 'static', keyboard: false,
        size: 'lg',
        resolve: {
          CompanyInfo: function () {
            return {
              data: companyData
            };
          }
        }
      });
    }

    $scope.StringToDate = function (datestring) {
      return new Date(datestring);
    }
    $scope.formatDate = function (date, format) {
      if (!format) format = 'YYYY-MM-DD hh:mm:ss.SSS';
      format = format.replace(/YYYY/g, date.getFullYear());
      format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
      format = format.replace(/DD/g, ('0' + date.getDate()).slice(-2));
      format = format.replace(/hh/g, ('0' + date.getHours()).slice(-2));
      format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
      format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
      if (format.match(/S/g)) {
        var milliSeconds = ('00' + date.getMilliseconds()).slice(-3);
        var length = format.match(/S/g).length;
        for (var i = 0; i < length; i++) format = format.replace(/S/, milliSeconds.substring(i, i + 1));
      }
      return format;
    };

    //企業ごとの設定を変更
    $scope.updateCustomSetting = function (companyData) {
      var modalInstance = $modal.open({
        templateUrl: 'app/company/admin/support/setting.html',
        controller: 'CompanySupportSettingCtrl',
        windowClass: 'modal-rename-window',
        backdrop: 'static', keyboard: false,
        size: 'lg',
        resolve: {
          CompanyInfo: function () {
            return {
              data: companyData,
              token: $scope.encToken
            };
          }
        }
      });

    }

    $scope.showDeviceInfo = function (companyData) {
      var modalInstance = $modal.open({
        templateUrl: 'app/company/admin/support/deviceList.html',
        controller: 'CompanySupportDeviceListCtrl',
        windowClass: 'modal-rename-window',
        backdrop: 'static', keyboard: false,
        size: 'lg',
        resolve: {
          CompanyInfo: function () {
            return {
              data: companyData,
              token: $scope.encToken
            };
          }
        }
      });

    }


    $scope.updateSetting = function() {
      $window.location.href = "/company/system";

    }
    $scope.logout = function() {
      Auth.logout();
      LimaRedirect.go({path:'/login', returnPath:'/', clearCache:true});
    };

  }]);
