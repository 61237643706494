'use strict';

var app = angular.module('limaApp');

app.directive('limaEmailValidationPattern', [
function () {
  return {
    restrict: 'A',
    priority: 1,
    require: 'ngModel',
    link: function (scope, element, attrs, ctrl) {
      if (!ctrl || !ctrl.$validators || !ctrl.$validators.email || !attrs.limaEmailValidationPattern) {
        return;
      }
      var regex = new RegExp(attrs.limaEmailValidationPattern);
      ctrl.$validators.email = function (value) {
        return ctrl.$isEmpty(value) || (typeof value === 'string' && regex.test(value));
      };
    }
  };
}]);

app.filter('limaDisplayBytes', ['$filter',
function($filter) {
  return function(bytes, precision) {
    if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) return '-';
    if (bytes === 0) return '0 bytes';
    if (typeof precision === 'undefined') precision = 1;
    var units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
    var number = Math.floor(Math.log(bytes) / Math.log(1024));
    if (number === 0) precision = 0;
    return $filter('number')(bytes / Math.pow(1024, Math.floor(number)), precision) +  ' ' + units[number];
  }
}]);

app.filter('limaDurationText', ['$translate',
function ($translate) {
  return function (duration, scale, omit) {
    try {
      scale = scale || 1000000; // micro sec.
      omit = omit || false;
      if (typeof duration === 'string') duration = parseInt(duration);
      if (typeof duration !== 'number' || isNaN(duration)) return 'N/A';
      var sign = '';
      if (duration < 0) {sign = '-'; duration = -duration; }
      duration = Math.ceil(duration / scale);
      var hh = Math.floor(duration / 3600);
      var mm = Math.floor((duration -= hh * 3600) / 60);
      var ss = duration -= mm * 60;
      var text = '';
      if (hh) {
        text += hh + $translate.instant('UNIT.HOUR');
        if (!omit && !mm) mm = '0';
        if (!omit && !ss) ss = '0';
      }
      if (mm) {
        text += mm + $translate.instant('UNIT.MINUTE');
        if (!omit && !ss) ss = '0';
      }
      if (!text && !ss) {
        sign = '';
        ss = '0';
      }
      if (ss) {
        text += ss + $translate.instant('UNIT.SECOND');
      }
      return sign + text;
    } catch (ex) {
      return '';//'N/A';
    }
  };
}]);

app.filter('limaDurationHHMMSS', [
function () {
  return function (duration, scale) {
    try {
      scale = scale || 1000000; // micro sec.
      if (typeof duration === 'string') duration = parseInt(duration);
      if (typeof duration !== 'number' || isNaN(duration)) return 'N/A';
      var sign = '';
      if (duration < 0) {sign = '-'; duration = -duration; }
      duration = Math.ceil(duration / scale);
      var hh = Math.floor(duration / 3600);
      var mm = Math.floor((duration -= hh * 3600) / 60);
      var ss = duration -= mm * 60;
      if (hh > 0) return sign + hh + ':' + ('0' + mm).slice(-2) + ':' + ('0' + ss).slice(-2);
      return sign + mm + ':' + ('0' + ss).slice(-2);
    } catch (ex) {
      return '';//'N/A';
    }
  };
}]);

app.filter('limaDateTime', [
function () {
  return function (date, time, options) {
    var d = null;
    if (!date || !(date instanceof Date)) {
      return null;
    } else if (time === null) {
      d = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    } else if (time instanceof Date) {
      d = new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.getHours(), time.getMinutes(), time.getSeconds(), time.getMilliseconds());
    } else {
      d = new Date(date);
      if (typeof time === 'object') options = time;
    }
    if (!(d instanceof Date) || d.toString() === 'Invalid Date') {
      return null;
    }
    options = options || {};
    var add = options.add || {};
    if (add.year && typeof add.year === 'number') d.setFullYear(d.getFullYear() + add.year);
    if (add.month && typeof add.month === 'number') d.setMonth(d.getMonth() + add.month);
    if (add.date && typeof add.date === 'number') d.setDate(d.getDate() + add.date);
    if (add.hour && typeof add.hour === 'number') d.setHours(d.getHours() + add.hour);
    if (add.minute && typeof add.minute === 'number') d.setMinutes(d.getMinutes() + add.minute);
    if (add.second && typeof add.second === 'number') d.setSeconds(d.getSeconds() + add.second);
    if (add.millisecond && typeof add.millisecond === 'number') d.setMilliseconds(d.getMilliseconds() + add.millisecond);
    var truncate = options.truncate || {millisecond:true};
    if (truncate.month === true) d = new Date(d.getFullYear(), 0, 1);
    else if (truncate.date === true) d = new Date(d.getFullYear(), d.getMonth(), 1);
    else if (truncate.hour === true) d = new Date(d.getFullYear(), d.getMonth(), d.getDate());
    else if (truncate.minute === true) d = new Date(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), 0, 0);
    else if (truncate.second === true) d = new Date(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), 0);
    else if (truncate.millisecond === true) d = new Date(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds());
    return d;
  };
}]);


app.factory('limaOptionService', ['$http',
function LimaOptionService($http) {
  return {
    getTransferMaxSize: function (optionService) {
      var findBase = _.find(optionService, function(oc){
        return oc.name === 'base' ? true : false;
      });
      var find = _.find(optionService, function(op){
        return op.name === 'DataTransferAmount1G' ? true : false;
      });
      var size=1*1024*1024*1024 * findBase.amount;
      if (find) {
        size+=find.amount*1024*1024*1024 * findBase.amount;
      }
      return size;
    },
    getOptionService: function (encToken, callback) {
      $http.get('/api/v1/users/me').
      success(function(datas) {
        var userinfo=datas;
        var user;
        var opt = "";
        if (encToken != null ) {
          opt = '?access_token=' + encToken;
        }
        $http.get('/api/v1/company/' + userinfo.companyId + opt ).
        success(function(datasC) {
          user = datasC;
          $http.get('/api/v1/option-service/' + userinfo.companyId + opt ).
          success(function(datas) {
            callback(datas); //契約情報
          });

        });
      });
    }
  };
}]);

app.factory('limaPlaylistService1', ['$http', '$q',
function LimaPlaylistService($http, $q) {
  return {

    createPlaylistHTML: function (option,callback) {
      //selectLayoutIndex
      //contentsInfo
      //name
      var playlistId = option.playlistId;
      var selectedTw = _.filter(option.contentsInfo, function(tw){return tw.checked;});
      var params = option.contentsInfo;
      // var params = [];
      // _.forEach(selectedTw, function(item){
      //   var data = {
      //     contentId: item.id
      //   };
      //   .push(data);
      // })

      if (_.isUndefined( option.selectLayoutIndex)) {
        console.log( "option.selectLayoutIndex undefined" );
        callback(null);;
      } else {
        var postdata = {
          //channel: option.name,
          layoutId: option.selectLayoutIndex,
          Materials: params,
          temporary: false,
          playlist: playlistId,
          playlistSetting: option.playlistSetting,
          name: option.name
        }
        $http.post('/api/v1/playlists/html', postdata)
        .success(function(res) {
          console.log(res.html);
          if (callback) {
            callback(playlistId);
          }
        });

      }

    }
  };
}]);

app.factory('limaPlaylistService', ['$http', '$q', 'limaPlaylistService1',
function LimaPlaylistService($http, $q, limaPlaylistService1) {
  return {
    UpdatePlaylistHTML: function(playlistId, playlists) {
      var defer = $q.defer();

      var option = {};
      var find = _.find(playlists, function(p){return p.playlistId ===playlistId;});

      $http.get('/api/v1/playlists/' + playlistId + '/contents').
      success(function(cont) {
        option.contentsInfo = _.sortBy(cont, function(item) {
          return item.playIndex;
        });
        option.contentsInfo = _.filter(option.contentsInfo, function(c){
          if (c.enabled) {
            return true;
          }
        });

        option.playlistId = playlistId;
        option.selectLayoutIndex = find.layoutId;
        option.name = find.playlistName;
        option.playlistSetting = find.setting;
        limaPlaylistService1.createPlaylistHTML(option,function(res){
          defer.resolve();
        });

      }).
      error(function(cont) {
        option.contentsInfo = _.sortBy(cont, function(item) {
          return item.playIndex;
        });
        option.contentsInfo = _.filter(option.contentsInfo, function(c){
          if (c.enabled) {
            return true;
          }
        });

        option.playlistId = playlistId;
        option.selectLayoutIndex = find.layoutId;
        option.name = find.playlistName;
        option.playlistSetting = find.setting;
        limaPlaylistService1.createPlaylistHTML(option,function(res){
          defer.resolve();
        });

      });

      return defer.promise;


    }

  };
}]);


app.factory('limaDiskService', ['$http','$filter',
function LimaDiskService($http,$filter) {
  return {
    getTransferWarningRemainSize: function() {
      return 50*1024*1024;  //50M
    },
    getTransferInfo: function (callback) {
      $http.get('/api/v1/device/transfer').
      success(function(datas) {

        var size = 0;
        _.forEach(datas, function(dv){
          size += dv.transferedSize;
        });
        callback({size:size});

      });

    },

    getDiskInfo: function (callback) {
      var result = {};
      result.awsDiskUsageInMB = {};
      result.diskUsageInMB = {};
      result.awsDiskUsageInMB.licensed = 0;
      $http.get('/api/v1/configurations/AWSS3UploadServer').success(function (resAws) {
        if (resAws.enabled) {
          $http.get('/api/v1/system-info/aws').then(function (res) {
            result.awsDiskUsageInMB.use = {size: $filter('number')(Math.round(res.data.totalSize / 1024)), sizeByte:res.data.totalSize};
            //$scope.awsdiskUsageInMB.available = {size: $filter('number')(Math.round(info.diskUsageInKB.available / 1024))};
            callback(result);
          });
        } else {
          $http.get('/api/v1/system-info').then(function (res) {
            var info = res.data;
            result.diskUsageInMB.total = {size: $filter('number')(Math.round(info.diskUsageInKB.total / 1024)), sizeByte:res.data.totalSize};
            result.diskUsageInMB.use = {size: $filter('number')(Math.round(info.diskUsageInKB.usage / 1024)), sizeByte:res.data.totalSize};
            callback(result);
          });
        }
      });
    }
  };
}]);

app.factory('limaShowWaitDialog', ['$uibModal','$sce',
function LimaShowWaitDialog($modal,$sce) {
  return {
    hideWait : function(){
      if (this.modalInstance) {
        this.modalInstance.close();
        this.modalInstance = null;
      }
    },
    showWait: function (scopeParent,headerTitle , title) {
      var scope = {modal: {
        dismissable: true,
        title: headerTitle,
        html: '<div><span class="glyphicon glyphicon-refresh glyphicon-refresh-animate")></span>' + '  ' + title + '...' + '</div>'
      }};

      var modalScope = scopeParent.$new();
      scope = scope || {};
      scope.trustAsHtml = scope.trustAsHtml || function (s) { return $sce.trustAsHtml(s); };
      var modalClass = 'modal-warning';

      angular.extend(modalScope, scope);

      this.modalInstance = $modal.open({
        templateUrl: 'components/modal/modal.html',
        windowClass: modalClass,
        backdrop: 'static', keyboard: false,
        size: 'sm',
        scope:modalScope
      });
      this.modalInstance.result.then(function(results) {
      }, function() {
        //閉じられた
  //        console.log("aaa");
      });


    }
  };
}]);

app.factory('ContentUtil', function () {
  return{
    getFileformatName : function(content) {
      if (content.files) {
        if (content.files.length) {
          return content.files[0].formatName;
        }
      }
      return '';
    },

    isImage : function(content) {
      if (this.getFileformatName(content).indexOf("image") !== -1) {
          return true;
      }
      if (this.getFileformatName(content).indexOf("png") !== -1) {
        return true;
      }
      return false;
    },

    isVideo : function(content) {
      if (this.getFileformatName(content).indexOf("mov") !== -1) {
          return true;
      }
      return false;
    },

    isText : function(content) {
      if (this.getFileformatName(content).indexOf("tty") !== -1) {
          return true;
      }
      return false;
    },

    getDurationSec : function(duration){
      var scale = 1000000; // micro sec.
      var sign = '';
      if (duration < 0) {sign = '-'; duration = -duration; }
      var sec = Math.ceil(duration / scale);

      return sec;
    },
    getDurationText : function(duration, scale) {
      try {
        scale = scale || 1000000; // micro sec.
        if (typeof duration === 'string') duration = parseInt(duration);
        if (typeof duration !== 'number' || isNaN(duration)) return 'N/A';
        var sign = '';
        if (duration < 0) {sign = '-'; duration = -duration; }
        duration = Math.ceil(duration / scale);
        var hh = Math.floor(duration / 3600);
        var mm = Math.floor((duration -= hh * 3600) / 60);
        var ss = duration -= mm * 60;
        return sign + ('0' + hh).slice(-2) + ':' + ('0' + mm).slice(-2) + ':' + ('0' + ss).slice(-2);
      } catch (ex) {
        return '';//'N/A';
      }
    }
  };
});

app.factory('MediaedgeAdmin', function () {
  const magic_number = "0782651551";
  const registIdKey = "registId";
  return{
    getLoginInfo : function($http, callback){
      var config;
      $http.get('/api/v1/configurations/RegistManageServer').
      success(function(datas) {
        config = datas;
        const url = config.setting.protocol
        + "://"
        + config.setting.domain
        + ":"
        + config.setting.port
        + '/api/v1/company/login';

        var xmlHttpRequest = new XMLHttpRequest();
        xmlHttpRequest.onreadystatechange = function() {
          if( this.readyState === 4 ) {
            var info = null;
            if ( this.status === 200 && this.response ) {
              info = this.response;
              if (JSON && navigator.userAgent.indexOf('Trident') !== -1) {
              //IEの場合、レスポンスを置き換える
              info  = JSON.parse(this.response );
              }
              info.config = config;
            }
            callback(null , info);
          }
        };
        xmlHttpRequest.open( 'GET', url, true );
        xmlHttpRequest.responseType = 'json';
        xmlHttpRequest.send( null );

      });
    },

    setRegistId : function($http, callback ) {

      this.getLoginInfo($http, function(err, info){
        /**/
        if (!info) {
          callback(null);
          return;
        }

        const encToken = encodeURIComponent(info.token);
        const url2 = info.config.setting.protocol
        + "://"
        + info.config.setting.domain
        + ":"
        + info.config.setting.port
        + '/api/v1/creditcard/enc/' + magic_number + '?access_token=' + encToken;

        var xmlHttpRequest2 = new XMLHttpRequest();
        xmlHttpRequest2.onreadystatechange = function() {
          if( this.readyState === 4 ) {
            var creditCardInfo = null;
            if ( this.status === 200 && this.response ) {
              var info = this.response;
              if (JSON && navigator.userAgent.indexOf('Trident') !== -1) {
              //IEの場合、レスポンスを置き換える
              info  = JSON.parse(this.response );
              }
              const storage = sessionStorage;
              storage.setItem(registIdKey, info.cidtoken);
              callback(info);
            }
          }
        }
        xmlHttpRequest2.open( 'GET', url2, true );
        //xmlHttpRequest2.withCredentials = true;
        xmlHttpRequest2.responseType = 'json';
        xmlHttpRequest2.send( null );
      });

    },

    checkRegistId : function($http, callback ) {
      const storage = sessionStorage;
      const registId = storage.getItem(registIdKey);
      if ( typeof registId === 'undefined' || registId == null || registId.length == 0 ) {
        callback(false, null );
      }
      else {
        this.getLoginInfo($http , function(err, result){
//        $http.get('/api/v1/company/login').success(function (result) {
          const encToken = encodeURIComponent(result.token);

          const url2 = result.config.setting.protocol
          + "://"
          + result.config.setting.domain
          + ":"
          + result.config.setting.port
          + '/api/v1/creditcard/dec/' +  encodeURIComponent(registId) + '?access_token=' + encToken;

          var xmlHttpRequest2 = new XMLHttpRequest();
          xmlHttpRequest2.onreadystatechange = function() {
            if( this.readyState === 4 ) {
              var creditCardInfo = null;
              if ( this.status === 200 && this.response ) {
                var info = this.response;
                if (JSON && navigator.userAgent.indexOf('Trident') !== -1) {
                //IEの場合、レスポンスを置き換える
                info  = JSON.parse(this.response );
                }
                const val = info.companyId;
                if ( val === magic_number) {
                  callback(true, result);
                }
                else {
                  callback(false, result);
                }
              } else {
                callback(false, null);
              }
            }
          }
          xmlHttpRequest2.open( 'GET', url2, true );
          xmlHttpRequest2.responseType = 'json';
          xmlHttpRequest2.send( null );

        });
      }
    }
  };
});

