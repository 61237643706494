'use strict';

var app = angular.module('limaApp');


app.controller('CompanyInfoCtrl', ['$scope', '$http', '$location', '$timeout', '$translate','$uibModalInstance','CompanyInfo', 'Modal', 'MediaedgeAdmin',
function($scope, $http, $location, $timeout,$translate, $modalInstance,CompanyInfo, Modal, MediaedgeAdmin) {

  $timeout(function() {
    $scope.companyInfo = CompanyInfo.data;

  });
  $scope.cancel = function() {
    //$modalInstance.dismiss('cancel');

  }
  $scope.apply = function () {
    $modalInstance.close({name:$scope.formInput.inputname});
    $modalInstance.dismiss('done');
    //$dismiss();
  }
  $scope.cancel = function() {
    $modalInstance.dismiss('cancel');

  }
  $scope.StringToDate = function(datestring){
    return new Date(datestring);
  }
  $scope.formatDate = function (date, format) {
    if (!format) format = 'YYYY-MM-DD hh:mm:ss.SSS';
    format = format.replace(/YYYY/g, date.getFullYear());
    format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
    format = format.replace(/DD/g, ('0' + date.getDate()).slice(-2));
    format = format.replace(/hh/g, ('0' + date.getHours()).slice(-2));
    format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
    format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
    if (format.match(/S/g)) {
      var milliSeconds = ('00' + date.getMilliseconds()).slice(-3);
      var length = format.match(/S/g).length;
      for (var i = 0; i < length; i++) format = format.replace(/S/, milliSeconds.substring(i, i + 1));
    }
    return format;
  };

  $scope.deleteCampany = function () {
    Modal.confirm.yesno({
      title: $translate.instant('COMMON.CONFIRM'),
      html: "この企業を削除しますか？",
      btnClassYes: 'btn-btn-danger',
      btnCaptionYes: $translate.instant('COMMON.YES'),
      btnClassNo: 'btn-default',
      btnCaptionNo: $translate.instant('COMMON.NO'),
      modalClass: 'modal-danger'
    }, function (yes) {
      if (yes) {
        deleteRequest(function(err ) {
          if ( err ) {
            Modal.confirm.message({title:"",
            html:"削除できませんでした",
            modalClass:'modal-warning'});
          }
          else {
            $modalInstance.close('done');
          }
        });
      }
    });

  };

  function deleteRequest(callback ) {
    MediaedgeAdmin.getLoginInfo($http,function(err ,info) {
      $scope.regInfo = info;

      const url = $scope.regInfo.config.setting.protocol
                + "://"
                + $scope.regInfo.config.setting.domain
                + ":"
                + $scope.regInfo.config.setting.port
                + '/api/v1/company/info/delete/' + CompanyInfo.data.companyId 
                + "?access_token=" + info.token;

      $.ajax({
        type: 'DELETE',
        url: url,
        contentType: 'application/json', // リクエストの Content-Type
        dataType: 'json',
        processData: false,
//        data: JSON.stringify(postdata),
        success: function(data){
          callback(0);
        },
        error: function(data){
          callback(1);
        }
      });
    });
  }

}]);
