'use strict';

var app = angular.module('limaApp');
var async = require('async');

app.controller('RegisterCtrl', ['$scope', '$translate', 'Auth', '$http', '$location', '$window', '$timeout', 'MediaedgeAdmin', 'Modal','LimaRedirect', 'limaShowWaitDialog',
function ($scope, $translate, Auth, $http, $location, $window, $timeout,MediaedgeAdmin, Modal, LimaRedirect, limaShowWaitDialog ) {
  if (typeof $scope.encToken === 'undefined' ) {
    // メディアエッジ社内向けページでは、registIdがSessionStrageに設定されていること（電話番号を暗号化して使用）
    MediaedgeAdmin.checkRegistId($http, function(can, tokenResult) {
      if ( !can ) {
        $window.location.href="/" ;
        return;
      }
      else {
        $scope.encToken = encodeURIComponent(tokenResult.token);
        $scope.user = tokenResult.user
      }
    });
  }
  $scope.pageType = 'companyInfo';
  $scope.errors = {};
  $scope.registForCorp = false;

  $scope.userinfo = null;
  $scope.isSelectedOptions = false; // オプション選択画面を表示したかどうか（戻っても選択状態を保持する）

  //- ===========================
  //-  オプションテーブル関連。pugと連携するものはここに定義
  const _selectOptionLibrary = require('./_selectOptionLibrary');
  $scope.optlib = new _selectOptionLibrary($scope, MediaedgeAdmin);
  $scope.optionServiceList= undefined;
  $scope.totalPrices = 0;       // 費用合計
  $scope.initialPrices = $scope.optlib.getInitialPrices();  // 契約手数料
  $scope.changeDeviceChoise = function (optName, optChecked) { return $scope.optlib.changeDeviceChoise($scope, optName, optChecked);  }
  $scope.getPackageType = function() { return $scope.selectPackage.type; }
  //- ===========================
  $scope.selectPackage = {
    type: 0
  }
  $scope.inputCompanyInfo = {
    companyName: '',
    divisionName: '',
    staffName: '',
    email: '',
    email_chk: ''
  }

  $scope.cancel = function() {
    //window.history.back();
  }


  $scope.nextToOption = function() {
    var msg = checkInputInfo();
    if ( $scope.registForCorp ) {
      msg = msg + $scope.checkInputCorpInfo();
    }
    if (msg.length > 0 ) {
      Modal.confirm.message({
        title:$translate.instant('COMMON.CONFIRM'),
        html:"必須項目の入力が正しくできていません",
        modalClass:'modal-warning'
      });
      return;
    }
    else {
      isEmailExist(function(exist){
        if ( exist ) {
          //データが取得できたら
          Modal.confirm.yesno({
            title: $translate.instant('COMMON.CONFIRM'),
            html: "このメールアドレスは登録されています。登録処理を続けますか？",//$translate.instant('USER.USER_DELETE_OWN_MESSAGE'),
            btnClassYes: 'btn-default',
            btnCaptionYes: $translate.instant('COMMON.YES'),
            btnClassNo: 'btn-danger',
            btnCaptionNo: $translate.instant('COMMON.NO'),
            modalClass: 'modal-danger'
          }, function (yes) {
            if (!yes) {
              //存在するときはページ遷移しない
              $scope.pageType = 'companyInfo';
              return;
            }
          });
        }
      });
    }

    $timeout(function(){
      $scope.pageType = 'optionInfo';
      if (!$scope.isSelectedOptions ) {
        $scope.optlib.getListOfOptionService( $scope, $http, $timeout, $scope.encToken, function() {
          $scope.isSelectedOptions = true;
        });
      }
    });
}
  $scope.changeSelectPackage = function(type) {
    $scope.isSelectedOptions  =  false;　//オプションの取得し直し
    $scope.selectPackage.type = type;
  }

  //--------------------------------------------
  // オプションの登録ページ
  //--------------------------------------------
   //option
   $scope.backToRegister = function() {
    $timeout(function(){
      $scope.pageType = 'companyInfo';
    });
  }

  //確認ページ表示
  $scope.nextToResultOption = function() {
    $timeout(function(){
      $scope.pageType = 'optionResultInfo';
    });
  }


  //--------------------------------------------
  // オプションの確認ページ
  //--------------------------------------------
  //オプション選択ページに戻る
  $scope.backToOption = function() {
    $timeout(function(){
      $scope.pageType = 'optionInfo';
    });
  }

  $scope.nextToRegist = function() {
    if ( $scope.registForCorp ) {
      $scope.doRegistSales(function(salesId) {
        doRegisterCompany($scope.selectedYearChoise.value * 12, $scope.mediaedgeStaffChoise.value , salesId);
      });
    }
    else {
      doRegisterCompany(1, "", 0);
    }
    $timeout(function(){
      $scope.pageType = 'registedUserInfo';
    });
  }

  $scope.registerdResult = {};
  //--------------------------------------------
  // 企業を登録する
  //--------------------------------------------
  function doRegisterCompany(month, meStaffName, salesId ) {
    limaShowWaitDialog.showWait($scope,$translate.instant('USER.USER_ADD_SUBMIT'),$translate.instant('UserLogin.Registering'));

    //var info = $scope.regCompany;
    var postdata = {
      companyName: $scope.inputCompanyInfo.companyName,
      divisionName: $scope.inputCompanyInfo.divisionName,
      staffName: $scope.inputCompanyInfo.staffName,
      email: $scope.inputCompanyInfo.email,
      salesId: salesId,
      options : $scope.optionServiceList,
      month: month,
      creditCardPayment : $scope.registForCorp  ? 0 : 1,
      meStaffName : meStaffName,
      packageType: $scope.selectPackage.type,
      //----
      emailSubject: '【つながるサイネージ】ご登録ありがとうございます',
      emailCreditcardStr: $scope.registForCorp ? '' : 'クレジットカードでの決済はまだ行われていません。',
      emailDemoDayStr: $scope.registForCorp ? '' : '日間、体験版として試用が行えます。',
      emailPaymentDaysStr: $scope.registForCorp ? '' : '日以内に決済処理を行ってください。',
      emailLanguage: 'ja',
      emailPackageName: $scope.selectPackage.type === 1 ? 'もっと！つながるサイネージ'　: 'つながるサイネージ'
    };

    const url = $scope.regInfo.config.setting.protocol
    + "://"
    + $scope.regInfo.config.setting.domain
    + ":"
    + $scope.regInfo.config.setting.port
    + '/api/v1/company' + "?access_token=" + $scope.regInfo.token;

    $.ajax({
      type: 'POST',
      url: url,
      contentType: 'application/json', // リクエストの Content-Type
      dataType: 'json',
      processData: false,
      data: JSON.stringify(postdata),
      success: function(data){
        $scope.registerdResult = data;
        $timeout(function() {
          $scope.registerdResult.companyId = data.companyId;
          limaShowWaitDialog.hideWait();
          Auth.logout();
        })
      },
      error: function(data){
      }
    });
  }

  $scope.downloadRegistText = function() {
    const blob = new Blob([$scope.registerdResult.registInfo.txt], {type: 'text/plain'});
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.download = "ユーザー登録_" + $scope.registerdResult.companyId + ".txt";
    a.href = url;
    a.click();
    a.remove();
    URL.revokeObjectURL(url);
}

  //--------------------------------------------
  //ログインページに移る
  //--------------------------------------------
  $scope.endToRegister = function() {
//    Auth.logout();
    if (!$scope.registerdResult.companyId) {
      return false;
    }
    LimaRedirect.go({path:'/company/list', returnPath:'/', clearCache:true});
  }

  //--------------------------------------------
  function checkInputInfo() {
    var msg = "";
    if (typeof $scope.inputCompanyInfo.companyName === 'undefined'
        || $scope.inputCompanyInfo.companyName == "" ) {
      msg += "\n - 組織の名称を入力してください";
    }
    if (typeof $scope.inputCompanyInfo.email === 'undefined'
       || !$scope.MailCheck($scope.inputCompanyInfo.email ) ) {
      msg += "\n - エンドユーザの正しいメールアドレスを入力してください";
    }
    else {
      if ( $scope.inputCompanyInfo.email !== $scope.inputCompanyInfo.email_chk ) {
        msg += "\n - メールアドレスが一致しません";
      }
    }
    return msg;
  }

  // -------------------------------------------------------------------
  //emailが登録済みかどうかチェック
  // -------------------------------------------------------------------
  function isEmailExist(callback ) {

    const url = $scope.regInfo.config.setting.protocol
    + "://"
    + $scope.regInfo.config.setting.domain
    + ":"
    + $scope.regInfo.config.setting.port
    + '/api/v1/company/info/get/'+ encodeURIComponent($scope.inputCompanyInfo.email) + "?access_token=" + $scope.regInfo.token;

    $.ajax({
      type: 'GET',
      url: url,
      contentType: 'application/json', // リクエストの Content-Type
      dataType: 'json',
      processData: false,
      success: function(data){
        if (data.email === $scope.inputCompanyInfo.email ) {
          callback( true );
        }
        else {
          callback( false );
        }
      },
      error: function(data){
        callback( false );
      }
    });
  }

  // -------------------------------------------------------------------
  // メールアドレスチェック関数
  // -------------------------------------------------------------------
  $scope.MailCheck = function ( mail ) {
    if (mail.length == 0 ) {
      return false;
    }
    var mail_regex1 = new RegExp( '(?:[-!#-\'*+/-9=?A-Z^-~]+\.?(?:\.[-!#-\'*+/-9=?A-Z^-~]+)*|"(?:[!#-\[\]-~]|\\\\[\x09 -~])*")@[-!#-\'*+/-9=?A-Z^-~]+(?:\.[-!#-\'*+/-9=?A-Z^-~]+)*' );
    var mail_regex2 = new RegExp( '^[^\@]+\@[^\@]+$' );
    if( mail.match( mail_regex1 ) && mail.match( mail_regex2 ) ) {
        // 全角チェック
        if( mail.match( /[^a-zA-Z0-9\!\"\#\$\%\&\'\(\)\=\~\|\-\^\\\@\[\;\:\]\,\.\/\\\<\>\?\_\`\{\+\*\} ]/ ) ) { return false; }
        // 末尾TLDチェック（〜.co,jpなどの末尾ミスチェック用）
        if( !mail.match( /\.[a-z]+$/ ) ) { return false; }
        return true;
    } else {
        return false;
    }
  }

}]);

//===========================================
//
//
//RegisterCtrlの子：法人登録の場合のコントローラ
//
//
//===========================================
app.controller('RegisterCorporationCtrl', ['$scope', '$location', '$translate', 'Auth', '$http', '$window', '$timeout', '$controller', 'MediaedgeAdmin',
function ($scope, $location, $translate, Auth, $http, $window, $timeout, $controller, MediaedgeAdmin) {

  $scope.m_systemType = undefined; //AWSかオンプレ(1)か
  function getSystemType() {

    const url = $scope.regInfo.config.setting.protocol
    + "://"
    + $scope.regInfo.config.setting.domain
    + ":"
    + $scope.regInfo.config.setting.port
    + '/api/v1/configurations/RegistManageServer';  //token不要

    $.ajax({
      type: 'GET',
      url: url,
      contentType: 'application/json', // リクエストの Content-Type
      dataType: 'json',
      processData: false,
      success: function(data){
        if (data.system) {
          $scope.m_systemType = data.system.type;
          if ($scope.m_systemType) {
            if ($scope.selectPackage) {
              $scope.selectPackage.type = 1;
            }
          }
        }
      },
      error: function(data){
        //callback( false );
      }
    });
  }

  $scope.regInfo = null;
  // メディアエッジ社内向けページでは、registIdがSessionStrageに設定されていること（電話番号を暗号化して使用）
  MediaedgeAdmin.checkRegistId($http, function(can, tokenResult) {
    if ( !can ) {
      $window.location.href="/company" ;
      return;
    }
    else {
      $scope.encToken = encodeURIComponent(tokenResult.token);
      $scope.user = tokenResult.user

      MediaedgeAdmin.getLoginInfo($http,function(err ,info) {
        $scope.regInfo = info;
        getMediaedgeStaff();
        getSystemType();
      });
    }
  });

  $controller('RegisterCtrl', {
                                $scope:$scope,
                                $translate:$translate,
                                Auth:Auth,
                                $http:$http,
                                $location:$location,
                                $window:$window,
                                $timeout:$timeout,
                                MediaedgeAdmin:MediaedgeAdmin }); //This works
  $scope.registForCorp = true;  //$controllerの中でfalse にされるので、後から設定すること

  $scope.sales = {
    name: '',
    divisionName: '',
    userName: '',
    email: '',
    email_chk: ''
  }

  $scope.selectYear = [];
  $scope.selectYear.push({value:1});
  $scope.selectYear.push({value:5});
  $scope.selectedYearChoise = _.cloneDeep($scope.selectYear[0]);
  exports.changeSelectYear = function() {

  }

  $scope.mediaedgeStaff = [];
  function getMediaedgeStaff() {

    const url = $scope.regInfo.config.setting.protocol
    + "://"
    + $scope.regInfo.config.setting.domain
    + ":"
    + $scope.regInfo.config.setting.port
    + '/api/v1/company/mestaff/get/' + "?access_token=" + $scope.regInfo.token;

    $.ajax({
      type: 'GET',
      url: url,
      contentType: 'application/json', // リクエストの Content-Type
      dataType: 'json',
      processData: false,
      success: function(data){
        data.forEach(function(val){
          $scope.mediaedgeStaff.push({value:val.name} );
        });
        $timeout(function() {
          $scope.mediaedgeStaffChoise = [];//_.cloneDeep("");
        });
      },
      error: function(data){
        callback( false );
      }
    });
  }

  exports.changeMeStaffChoise = function() {

  }

  $scope.checkInputCorpInfo = function() {
    var msg = "";
    if (!$scope.m_systemType) {
    if (typeof $scope.sales.name === 'undefined'
        || $scope.sales.name == "" ) {
      msg += "\n - 販社名を入力してください";
    }
    if ( typeof $scope.sales.email  === 'undefined'
         || !$scope.MailCheck($scope.sales.email ) ) {
      msg += "\n - 販社担当者様の正しいメールアドレスを入力してください";
    }
    else {
      if ( $scope.sales.email !== $scope.sales.email_chk ) {
        msg += "\n - 販社担当者様のメールアドレスが一致しません";
      }
    }
    if (typeof $scope.mediaedgeStaffChoise.value == 'undefined' ) {
      msg += "\n - メディアエッジの営業担当者を選択してください";
    }
    }
    return msg;
  }
  //--------------------------------------------
  // 販社情報を登録する
  //--------------------------------------------
  $scope.doRegistSales = function(callback ) {
    var postdata = {
      name: $scope.sales.name,
      divisionName: $scope.sales.divisionName,
      staffName: $scope.sales.userName,
      email: $scope.sales.email,
      telephone: "",
      comment: ""
    }

    const url = $scope.regInfo.config.setting.protocol
    + "://"
    + $scope.regInfo.config.setting.domain
    + ":"
    + $scope.regInfo.config.setting.port
    + '/api/v1/company/sales' + "?access_token=" + $scope.regInfo.token;

    $.ajax({
      type: 'POST',
      url: url,
      contentType: 'application/json', // リクエストの Content-Type
      dataType: 'json',
      processData: false,
      data: JSON.stringify(postdata),
      success: function(data){
        if ( data == null ) {
          callback(0);
        }
        callback(data.id);
      },
      error: function(data){
        callback(0);
      }
    });
  }

}]);