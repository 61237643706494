'use strict';

var app = angular.module('limaApp');

app.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('companySupportDeviceList', {
    url: '/company/support/deviceinfo',
    templateUrl: 'app/company/admin/support/deviceInfo.html',
    controller: 'CompanySupportDeviceListCtrl',
    title: '登録情報',
    authenticate: false
  });
}]);
