'use strict';

var app = angular.module('limaApp');

app.controller('DeviceInfoCtrl', ['$scope', '$timeout', '$http', '$translate', '$sce', '$uibModal', 'Auth', 'socket','$window', 'deviceInfo',
function ($scope, $timeout, $http, $translate, $sce, $modal, Auth, socket,$window, deviceInfo) {

  //$window.document.title  = $translate.instant('NAVIGATION.DEVICESETTING');

  $scope.list = deviceInfo.list;
  $scope.list.name = deviceInfo.name;

 
  $scope.sPreviewLoadingErrorString1 = "";
  $scope.sPreviewLoadingErrorString2 = "";
  $scope.sPreviewLoadingErrorString3 = "";

  $scope.preview = null;
  socket.on('lima:device:capture:preview.error', function (res) {
    if (res.deviceId === $scope.list.deviceId) {
      $timeout(function() {
        $scope.bPreviewLoadingError='err';
        if (res.response) {
          if (res.response.errCode) {
            $scope.sPreviewLoadingErrorString1 = $translate.instant('DEVICE.INFO.CANNOT_CAPTURE1');
            $scope.sPreviewLoadingErrorString2 =  $translate.instant('DEVICE.INFO.CANNOT_CAPTURE2');
            $scope.sPreviewLoadingErrorString3 = $translate.instant('DEVICE.INFO.ERR_CODE') + ':' + res.response.errCode;
            if (res.response.errCode === 1) {
              $scope.sPreviewLoadingErrorString3 = $translate.instant('DEVICE.INFO.ERRCODE_1');
            }
          }
        }
      $scope.bPreviewLoading = false;
        $timeout(function(){
          //5�b��ɕ\������
          if ($scope.bPreviewLoadingError.length) {
            $scope.bPreviewLoadingError='';
            $scope.bPreviewLoading = false;
          }
        },5*1000);
      });
    }
  });

  socket.on('lima:device:capture:preview', function (res) {
    if (res.deviceId === $scope.list.deviceId) {
      $timeout(function() {
        $scope.bPreviewLoading = false;
        $scope.previewDateTime = new Date().setTime(res.time);
        $timeout(function(){
          $scope.preview  = res.file;
        });
      });
    }
  });

  socket.on('lima:device:status:filedownload', function (res) {
    if (res.deviceId === $scope.list.deviceId) {
      $timeout(function() {
        $scope.list.filedownloadStatus = res;
        console.log("filedownload " + res);
      });
      if ($scope.list.filedownloadStatus) {
        if ($scope.list.filedownloadStatus.status >= 2) {
          $timeout(function() {
            $scope.list.filedownloadStatus = null;
          } , 3000);
        }
      }
    }
  });

  $scope.bPreviewLoadingError = '';

  const URLJoin = (...args) =>
  args
    .join('/')
    .replace(/[\/]+/g, '/')
    .replace(/^(.+):\//, '$1://')
    .replace(/^file:/, 'file:/')
    .replace(/\/(\?|&|#[^!])/g, '$1')
    .replace(/\?/g, '&')
    .replace('&', '?');

  var m_timePostCapture = 0;
  function isIncludeNewImage(arr){
    _
    m_timePostCapture
  }

  function getCaptureImage() {
    if (deviceInfo.server.serverLB.length) {
      var param = {
        urlPath:URLJoin(deviceInfo.server.serverLB, '/api/v1/device/capture/' + $scope.list.companyId + '/' + $scope.list.deviceId) + '?access_token=' + deviceInfo.server.token,
        method : 'GET',
        param : {}
      }

      const url = deviceInfo.server.config.setting.protocol
        + "://"
        + deviceInfo.server.config.setting.domain
        + ":"
        + deviceInfo.server.config.setting.port
        + '/api/v1/system/message' + "?access_token=" + deviceInfo.server.regInfo.token;

      $.ajax({
        type: 'POST',
        url: url,
        dataType: 'json',
        processData: false,
        contentType: 'application/json',
        data:JSON.stringify(param),
        success: function (datas) {
            console.log(datas);
            var datasFind = _.find(datas,function(dt){
              if (dt.url.indexOf('/info/capture/preview') !== -1) {
                return true;
              }
            });
            if (datasFind) {
              $timeout(function(){
                $scope.bPreviewLoading = false;
                $scope.previewDateTime = new Date();
                $scope.previewDateTime.setTime(datasFind.time);
                $scope.preview = URLJoin(deviceInfo.server.serverLB, datasFind.url);
              });
            }
        }
      });
    } else {
      $http.get('/api/v1/device/capture/' + $scope.list.companyId + '/' + $scope.list.deviceId).
      success(function(datas) {
        console.log(datas);
        var datasFind = _.find(datas,function(dt){
          if (dt.url.indexOf('/info/capture/preview') !== -1) {
            return true;
          }
        });
        if (datasFind) {
          $timeout(function(){
            $scope.bPreviewLoading = false;
            $scope.previewDateTime = new Date();
            $scope.previewDateTime.setTime(datasFind.time);
            var server = deviceInfo.server.regData.loginpage.protocol
            + "://"
            + deviceInfo.server.regData.loginpage.domain
            + ":"
            + deviceInfo.server.regData.loginpage.port

            $scope.preview = URLJoin(server, datasFind.url);
          });
        }
      });
    }

  }

  $scope.bPreviewLoading = false;
  $scope.capturePreview = function() {
    $timeout(function() {
      $scope.bPreviewLoading = true;
      $scope.preview = null;
    });
    var devices = [];
    devices.push($scope.list);

    m_timePostCapture = new Date().getTime();

    if (deviceInfo.server.serverLB.length) {
      var param = {
        urlPath:URLJoin(deviceInfo.server.serverLB, '/api/v1/device/capture') + '?access_token=' + deviceInfo.server.token,
        method : 'POST',
        param : {data:{devices:devices}}
      }

      const url = deviceInfo.server.config.setting.protocol
        + "://"
        + deviceInfo.server.config.setting.domain
        + ":"
        + deviceInfo.server.config.setting.port
        + '/api/v1/system/message' + "?access_token=" + deviceInfo.server.regInfo.token;

      $.ajax({
        type: 'POST',
        url: url,
        dataType: 'json',
        processData: false,
        contentType: 'application/json',
        data:JSON.stringify(param),
        success: function (err) {
          $timeout(function() {
            getCaptureImage();
          }, 1000);
        }
      });
    } else {
      $http.post('/api/v1/device/capture', {data:{devices:devices}}).
      success(function(err) {
        $timeout(function() {
          getCaptureImage();
        }, 1000);
      });
    }


   $timeout(function(){
      //1����ɂ܂����ĂȂ�������G���[�ɂ���
      if ($scope.bPreviewLoading) {
        $scope.bPreviewLoadingError='error';
        $scope.bPreviewLoading = false;
        $timeout(function(){
          //5�b��ɕ\������
          if ($scope.bPreviewLoadingError.length) {
            $scope.bPreviewLoadingError='';
            $scope.bPreviewLoading = false;
          }
        },5*1000);
      }
    },30*1000);


//    socket.emit('lima:call-request:v1:device:capture',{devices:devices});
  };

  $scope.StringToDate = function(datestring){
    return new Date(datestring);
  }

  $scope.formatDate = function (date, format) {
    if (!format) format = 'YYYY-MM-DD hh:mm:ss.SSS';
    format = format.replace(/YYYY/g, date.getFullYear());
    format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
    format = format.replace(/DD/g, ('0' + date.getDate()).slice(-2));
    format = format.replace(/hh/g, ('0' + date.getHours()).slice(-2));
    format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
    format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
    if (format.match(/S/g)) {
      var milliSeconds = ('00' + date.getMilliseconds()).slice(-3);
      var length = format.match(/S/g).length;
      for (var i = 0; i < length; i++) format = format.replace(/S/, milliSeconds.substring(i, i + 1));
    }
    return format;
  };
 //pagenation�p
  $scope.captureList =
  {
    ajaxPagination: true,
    itemsByPage: 5,  //1�y�[�W�ɕ\�����鐔
    start: 0,         //offset
    onSelectPage : function (start) { //�y�[�W���I�����ꂽ
      this.start = start;
    },
    items:[]
  };

  $scope.doCaptureList = function(list) {
    if (deviceInfo.server.serverLB.length) {
      var param = {
        urlPath:URLJoin(deviceInfo.server.serverLB, '/api/v1/device/capture/' + $scope.list.companyId + '/' + $scope.list.deviceId) + '?access_token=' + deviceInfo.server.token,
        method : 'GET',
        param : {}
      }

      const url = deviceInfo.server.config.setting.protocol
        + "://"
        + deviceInfo.server.config.setting.domain
        + ":"
        + deviceInfo.server.config.setting.port
        + '/api/v1/system/message' + "?access_token=" + deviceInfo.server.regInfo.token;

      $.ajax({
        type: 'POST',
        url: url,
        dataType: 'json',
        processData: false,
        contentType: 'application/json',
        data:JSON.stringify(param),
        success: function (datas) {
          $timeout(function() {
            $scope.captureList.items = _.filter(datas, function(f){
              if (f.url.indexOf('/preview/') === -1 ) {
                f.url = URLJoin(deviceInfo.server.serverLB, f.url);
                return true;
              }
            });
            $scope.totalItems = $scope.captureList.length;
    
          });
  
        }
      });
    } else {
      $http.get('/api/v1/device/capture/' + $scope.list.companyId + '/' + $scope.list.deviceId).
      success(function(datas) {
        $timeout(function() {
          var server = deviceInfo.server.regData.loginpage.protocol
          + "://"
          + deviceInfo.server.regData.loginpage.domain
          + ":"
          + deviceInfo.server.regData.loginpage.port

                   
          $scope.captureList.items = _.filter(datas, function(f){
            if (f.url.indexOf('/preview/') === -1 ) {
              f.url = URLJoin(server, f.url); 
              return true;
            }
          });
          $scope.totalItems = $scope.captureList.length;
  
        });
      });
    }
  }
  $scope.doCaptureList();


  $scope.paginate = function(value) {
    var begin, end, index;
    begin = ($scope.currentPage - 1) * $scope.numPerPage;
    end = begin + $scope.numPerPage;
    index = $scope.captureList.indexOf(value);
    return (begin <= index && index < end);
  };



}]);
