'use strict';

var app = angular.module('limaApp');

app.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('companyInfo', {
    url: '/company/option',
    templateUrl: 'app/company/admin/option.html',
    controller: 'CompanyOptionCtrl',
    title: 'オプション情報',
    authenticate: false
  });
}]);
