'use strict';

var app = angular.module('limaApp');

app.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('companyEditInfo', {
    url: '/company/option/edit',
    templateUrl: 'app/company/admin/editOption.html',
    controller: 'CompanyEditOptionCtrl',
    title: 'プラン情報',
    authenticate: false
  });
}]);
