'use strict';

var app = angular.module('limaApp');

app.config(['$stateProvider',
function ($stateProvider) {
  $stateProvider
  .state('companyList', {
    url: '/company/list',
    templateUrl: 'app/company/admin/list.html',
    authenticate: false,
    controller: 'CompanyListCtrl',
    title: '登録一覧'
  });
}]);
