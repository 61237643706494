'use strict';

var app = angular.module('limaApp');


app.controller('CompanySupportDeviceListCtrl', ['$scope', '$translate','socket', 'Auth','$http', '$timeout', '$location','$cookies','$uibModalInstance','$uibModal','Modal','CompanyInfo','MediaedgeAdmin',
function($scope, $translate, socket, Auth, $http, $timeout, $location, $cookieStore,$modalInstance,$modal,Modal, CompanyInfo,MediaedgeAdmin) {

  $timeout(function() {
    $scope.companyInfo = CompanyInfo.data;

  });
  $scope.cancel = function() {
    //$modalInstance.dismiss('cancel');

  }
  $scope.apply = function () {
    $modalInstance.close({name:$scope.formInput.inputname});
    $modalInstance.dismiss('done');
    //$dismiss();
  }
  $scope.cancel = function() {
    $modalInstance.dismiss('cancel');

  }
  $scope.StringToDate = function(datestring){
    return new Date(datestring);
  }
  $scope.formatDate = function (date, format) {
    if (!format) format = 'YYYY-MM-DD hh:mm:ss.SSS';
    format = format.replace(/YYYY/g, date.getFullYear());
    format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
    format = format.replace(/DD/g, ('0' + date.getDate()).slice(-2));
    format = format.replace(/hh/g, ('0' + date.getHours()).slice(-2));
    format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
    format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
    if (format.match(/S/g)) {
      var milliSeconds = ('00' + date.getMilliseconds()).slice(-3);
      var length = format.match(/S/g).length;
      for (var i = 0; i < length; i++) format = format.replace(/S/, milliSeconds.substring(i, i + 1));
    }
    return format;
  };

  var m_serverLB = '';
  var m_serverLB_token = '';
  function getLBToken(callback) {

    var param = {
      urlPath:URLJoin(m_serverLB , '/auth/local'),
      method : 'POST',
      param : {
        loginAdmin:true,
        email: 'admin',
        password: 'Mediaedge4'
      }
    };

    const url = config.setting.protocol
      + "://"
      + config.setting.domain
      + ":"
      + config.setting.port
      + '/api/v1/system/message' + "?access_token=" + $scope.regInfo.token;

    $.ajax({
      type: 'POST',
      url: url,
      dataType: 'json',
      processData: false,
      contentType: 'application/json',
      data:JSON.stringify(param),
      success: function (datas) {
        m_serverLB_token = datas.token;
        callback(null);

      },
      error: function (data) {
      }
    });
  }

  function getLoadBalance(callback) {
    $http.get('/api/v1/system/loadbalance/' + CompanyInfo.data.companyId).
    success(function(datasLB) {
      if (datasLB) {
        if (datasLB.length) {
          if (datasLB[0].server.length){
            m_serverLB = datasLB[0].server;
            getLBToken(function(err){
              callback(null);
            });
          } else {
            callback(null);
          }
        } else {
          callback(null);
        }
      } else {
        callback(null);
      }
    });
  }

  $scope.devicelists = [];
  var config = [];
  $scope.regInfo = {};
  var regData = {};
  var getConfigration = function() {

    $scope.regInfo = null;
    MediaedgeAdmin.getLoginInfo($http, function (err, info) {
      $scope.regInfo = info;
      $http.get('/api/v1/configurations/RegistManageServer').
      success(function(datas) {
        config = datas;

        getRegistConf(function(reg) {
          regData = reg;
          getDevicelist();
        });
      });

    });

  }

  $scope.checkedlist = [];
  $scope.UpdateCheckedList = (function() {
    $scope.checkedlist = _.filter($scope.devicelists, function(list){return list.checked;});
  });

  $scope.controlLists = [];
  var getControlLists = function() {
    $http.get('/api/v1/controls').
    success(function(datas) {
      $scope.controlLists.items = datas.controls;
      _.forEach($scope.controlLists.items, function(dt){
        dt.displayName = dt.controlName;
      })
      $scope.controlLists.selected = $scope.controlLists.items[0];
    });

  };
  getControlLists();

  $scope.doControlDevice = function(){
    Modal.confirm.yesno({
      title: $translate.instant('COMMON.CONFIRM'),
      html: "デバイスコントロール(" + $scope.controlLists.selected.displayName + ")を行います。よろしいですか？",//$translate.instant('USER.USER_DELETE_OWN_MESSAGE'),
      btnClassYes: 'btn-default',
      btnCaptionYes: $translate.instant('COMMON.YES'),
      btnClassNo: 'btn-danger',
      btnCaptionNo: $translate.instant('COMMON.NO'),
      modalClass: 'modal-danger'
    }, function (yes) {
      if (!yes) {
        return;
      }

      var arrParam = [];
      var  selected = _.filter($scope.devicelists, function(list){return list.checked;});
      _.forEach(selected, function(sel){
        if (sel.deviceId){
          var param = {
            control:$scope.controlLists.selected,
            deviceId:sel.deviceId
          }
          arrParam.push(param);
        }
      });

      if (m_serverLB.length) {
        var param = {
          urlPath:URLJoin(m_serverLB, '/api/v1/device/control') + '?access_token=' + m_serverLB_token,
          method : 'POST',
          param : {data:arrParam}
        }

        const url = config.setting.protocol
          + "://"
          + config.setting.domain
          + ":"
          + config.setting.port
          + '/api/v1/system/message' + "?access_token=" + $scope.regInfo.token;

        $.ajax({
          type: 'POST',
          url: url,
          dataType: 'json',
          processData: false,
          contentType: 'application/json',
          data:JSON.stringify(param),
          success: function (datas) {
          },
          error: function (data) {
            Modal.confirm.message({
              title:$translate.instant('COMMON.CONFIRM'),
              html:'操作できませんでした',
              modalClass:'modal-warning'
            });
          }
        });
      } else {
        $http.post('/api/v1/device/control', {data:arrParam}).
        success(function(err) {
        });
      }


//      socket.emit('lima:call-request:v1:device:control',arrParam);

    });
  }

  var getDevicelist = function() {
    getLoadBalance(function(err){
      if (m_serverLB.length) {
        var param = {
          urlPath:URLJoin(m_serverLB, '/api/v1/device/device') + '?access_token=' + m_serverLB_token,
          method : 'GET',
          param : {}
        }

        const url = config.setting.protocol
          + "://"
          + config.setting.domain
          + ":"
          + config.setting.port
          + '/api/v1/system/message' + "?access_token=" + $scope.regInfo.token;

        $.ajax({
          type: 'POST',
          url: url,
          dataType: 'json',
          processData: false,
          contentType: 'application/json',
          data:JSON.stringify(param),
          success: function (datas) {
            $timeout(function(){
              $scope.devicelists = _.filter(datas, function(dt){
                return dt.companyId === CompanyInfo.data.companyId;
              });
            });
          },
          error: function (data) {
          }
        });
      } else {
        $http.get('/api/v1/device/device'+ '?access_token=' + CompanyInfo.token).
        success(function(datas) {
          $scope.devicelists = _.filter(datas, function(dt){
            return dt.companyId === CompanyInfo.data.companyId;
          });
        });
      }
    });
  };
  getConfigration();

  $scope.getName = function(macaddr) {
    var info = _.find($scope.devicelists, function(list){
      if (list.macaddr === macaddr) {
        return true;
      }
    });

    if (info) {
      if (_.isUndefined(info.name)) {
        return info.brand + ' ' + info.model;
      } else {
        return info.name;
      }
    }
    return '';
  };

  $scope.showDetail = function(list){
    var modalInstance = $modal.open({
      templateUrl: 'app/company/admin/support/deviceInfo.html',
      controller: 'DeviceInfoCtrl',
      backdrop: 'static', keyboard: false,
      size: 'lg',
      resolve: {
        deviceInfo: function() {
          return {
            list:_.cloneDeep(list),
            name: $scope.getName(list.macaddr),
            server : {
              serverLB: m_serverLB,
              token: m_serverLB_token,
              config: config,
              regInfo:$scope.regInfo,
              regData: regData
            }
          };
        }
      }
    });
    modalInstance.result.then(function (results) {
      // OK
    }, function () {
      // cancel
    });
  }

  function getRegistConf(callback) {
    MediaedgeAdmin.getLoginInfo($http,function(err ,info) {
      $scope.regInfo = info;

      const url = $scope.regInfo.config.setting.protocol
      + "://"
      + $scope.regInfo.config.setting.domain
      + ":"
      + $scope.regInfo.config.setting.port
      + '/api/v1/configurations/RegistManageServer';// + "?access_token=" + $scope.regInfo.token;

      $.ajax({
        type: 'GET',
        url: url,
        contentType: 'application/json', // リクエストの Content-Type
        dataType: 'json',
        processData: false,
//        data: JSON.stringify(postdata),
        success: function(data){
          callback(data);
        },
        error: function(data){
          callback(null);
        }
      });
    });
  }

  $scope.doCheckDebugLog = function(enable){
    var  selected = _.filter($scope.devicelists, function(list){return list.checked;});

    Modal.confirm.yesno({
      title: $translate.instant('COMMON.CONFIRM'),
      html: "ログレベルを変更します。よろしいですか？",//$translate.instant('USER.USER_DELETE_OWN_MESSAGE'),
      btnClassYes: 'btn-default',
      btnCaptionYes: $translate.instant('COMMON.YES'),
      btnClassNo: 'btn-danger',
      btnCaptionNo: $translate.instant('COMMON.NO'),
      modalClass: 'modal-danger'
    }, function (yes) {
      if (!yes) {
        return;
      }

      if (m_serverLB.length) {
        var param = {
          urlPath:URLJoin(m_serverLB, '/api/v1/device/setting') + '?access_token=' + m_serverLB_token,
          method : 'POST',
          param : {data:{devices:selected,level:enable ? 'debug' : 'info'}}
        }

        const url = config.setting.protocol
          + "://"
          + config.setting.domain
          + ":"
          + config.setting.port
          + '/api/v1/system/message' + "?access_token=" + $scope.regInfo.token;

        $.ajax({
          type: 'POST',
          url: url,
          dataType: 'json',
          processData: false,
          contentType: 'application/json',
          data:JSON.stringify(param),
          success: function (datas) {
          },
          error: function (data) {
            Modal.confirm.message({
              title:$translate.instant('COMMON.CONFIRM'),
              html:'操作できませんでした',
              modalClass:'modal-warning'
            });
          }
        });
      } else {
        $http.post('/api/v1/device/setting', {data:{devices:selected,level:enable ? 'debug' : 'info'}}).
        success(function(err) {
        });
      }

    });
  };

  var uploadReqInfo = {
    logFileName: '',
    deviceCount:0
  };
  var uploadReqInfo = {
    logFileName: '',
    deviceCount:0
  };
  $scope.uploadReqesting = false;

  function checkUploadedLogFunc(callback){
    if (m_serverLB.length) {
      var param = {
        urlPath:URLJoin(m_serverLB, '/api/v1/device/log') + '?access_token=' + m_serverLB_token,
        method : 'GET',
        param : {}
      }

      const url = config.setting.protocol
        + "://"
        + config.setting.domain
        + ":"
        + config.setting.port
        + '/api/v1/system/message' + "?access_token=" + $scope.regInfo.token;

      $.ajax({
        type: 'POST',
        url: url,
        dataType: 'json',
        processData: false,
        contentType: 'application/json',
        data:JSON.stringify(param),
        success: function (files) {
          callback(files);
        }
      });
    } else {
      $http.get('/api/v1/device/log/').
      success(function(files) {
        callback(files);
      });
    }
  }

  const URLJoin = (...args) =>
  args
    .join('/')
    .replace(/[\/]+/g, '/')
    .replace(/^(.+):\//, '$1://')
    .replace(/^file:/, 'file:/')
    .replace(/\/(\?|&|#[^!])/g, '$1')
    .replace(/\?/g, '&')
    .replace('&', '?');

  function checkUploadedLog(){
    checkUploadedLogFunc(function(files){

      console.log(files);
      var finds = _.filter(files, function(ff) {
        if (ff.indexOf(uploadReqInfo.logFileName) !== -1 ) {
          return true;
        }
      });
      if (finds.length !== uploadReqInfo.deviceCount) {
        $timeout(function(){
          checkUploadedLog();
        },5000);
      } else {
        $timeout(function(){
          $scope.uploadReqesting = false;
        });

        _.forEach(finds, function(ff){
          var link = document.createElement('a');
          var fileNameIndex = ff.lastIndexOf("/") + 1;
          var filename = ff.substr(fileNameIndex);
          link.download = filename;
          if (m_serverLB.length) {
            link.href = URLJoin(m_serverLB , ff);
          } else {
            link.href = regData.loginpage.protocol
            + "://"
            + regData.loginpage.domain
            + ":"
            + regData.loginpage.port
            + ff;
          }
          link.click();

        });
      }
    });
  }

  function postDeviceLog(selected, uploadReqInfo, callback){
    if (m_serverLB.length) {
      var param = {
        urlPath:URLJoin(m_serverLB , '/api/v1/device/log') + '?access_token=' + m_serverLB_token,
        method : 'POST',
        param : {devices:selected,filename:uploadReqInfo.logFileName}
      }

      const url = config.setting.protocol
        + "://"
        + config.setting.domain
        + ":"
        + config.setting.port
        + '/api/v1/system/message' + "?access_token=" + $scope.regInfo.token;

      $.ajax({
        type: 'POST',
        url: url,
        dataType: 'json',
        processData: false,
        contentType: 'application/json',
        data:JSON.stringify(param),
        success: function (datas) {
          callback(datas);
        }
      });
    } else {
      $http.post('/api/v1/device/log' , {devices:selected,filename:uploadReqInfo.logFileName}).
      success(function(datas) {
        callback(datas);
      });
    }
  }

  $scope.getDeviceLog = function () {
    var  selected = _.filter($scope.devicelists, function(list){return list.checked;});
    uploadReqInfo.deviceCount = selected.length;
    var now = new Date();
    uploadReqInfo.logFileName = ('000' + (now.getFullYear() + 0)).slice(-4) +
    ('0' + (now.getMonth() + 1)).slice(-2) +
    ('0' + (now.getDate() + 0)).slice(-2) +
    "_" +
    ('0' + (now.getHours() + 0)).slice(-2) +
    ('0' + (now.getMinutes() + 0)).slice(-2) +
    ('0' + (now.getSeconds() + 0)).slice(-2) +
    '.zip';

    $scope.uploadReqesting = false;
    postDeviceLog(selected,uploadReqInfo,function(datas){
      console.log(datas);

      var findSocket = _.find(datas, function(d){
        if (d.socketId.length){
          return true;
        }
      });
      if (findSocket) {
        $timeout(function(){
          $scope.uploadReqesting = true;
        });
        $timeout(function(){
          checkUploadedLog();
        },5000);
      }
    });


    //socket.emit('lima:call-request:v1:device:getlog',{devices:selected,filename:uploadReqInfo.logFileName});

  };
}]);
