'use strict';

var app = angular.module('limaApp', [
  'ngCookies',
  'ngResource',
  'ngSanitize',
  'btford.socket-io',
  'ui.router',
  'ui.router.state.events',
  'ui.bootstrap',
  'ngAnimate',
  'pascalprecht.translate',
  'ngTouch',
  //'ngDragDrop',
  'ui.sortable',

  'ngStorage',
  'isteven-multi-select',
  'smart-table'
   ,
   'angularFileUpload',
   require('angular-bootstrap-calendar'),
  'ngFileUpload'
  //  ,
  //  'angular-fullcalendar'
]);

app.config(['$stateProvider', '$urlRouterProvider', '$locationProvider', '$httpProvider', '$translateProvider', 'uibDatepickerConfig', 'uibDatepickerPopupConfig',
function ($stateProvider, $urlRouterProvider, $locationProvider, $httpProvider, $translateProvider, datepickerConfig, datepickerPopupConfig) {
  $urlRouterProvider.otherwise('/');
  $locationProvider.html5Mode(true);
  $httpProvider.interceptors.push('authInterceptor');

  //initialize get if not there
  if (!$httpProvider.defaults.headers.get) {
      $httpProvider.defaults.headers.get = {};
  }
  //disable IE ajax request caching
  $httpProvider.defaults.headers.get['If-Modified-Since'] = '0';
  $httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache';
  $httpProvider.defaults.headers.get['Pragma'] = 'no-cache';

  $translateProvider.useSanitizeValueStrategy('escapeParameters', 'sanitizeParameters');
  $translateProvider.useStaticFilesLoader({prefix: 'resources/i18n/locale-', suffix: '.json'});
  $translateProvider.preferredLanguage('ja');
  $translateProvider.fallbackLanguage('en');
  $translateProvider.useMissingTranslationHandlerLog();
/*
  $translateProvider.useLocalStorage(); // this will let the brower cache the string resource
*/

  datepickerConfig.showWeeks = false;
  if ($translateProvider.preferredLanguage() === 'ja') {
    datepickerConfig.formatDayTitle = 'yyyy年MMMM';
    datepickerPopupConfig.currentText = '今日';
    datepickerPopupConfig.clearText = '消去';
    datepickerPopupConfig.closeText = '閉じる';
    datepickerPopupConfig.altInputFormats = ['yyyy/M!/d!'];
  }


}]);


app.factory('LimaRedirect', ['$location', '$timeout', '$cookies', '$injector',
function LimaRedirect($location, $timeout, $cookies, $injector) {
  return {
    go: function (options) {
      options = options || {};
      if (!options.path) return;
      if (typeof options.delay === 'number') return $timeout(function () { delete options.delay; this.go(options); }.bind(this), options.delay);
      if (options.returnPath === true) options.returnPath = $location.path();
      if (options.returnPath && options.returnPath !== options.path) this.returnPath = options.returnPath;
      this.help = options.help || '';
      $location.path(options.path || '/');
      if (options.history === false) $location.replace();
      if (options.clearCache === true) {
        // remove any stale tokens
        // $cookies.remove('token', {path:'/', domain:$location.host()});
        $cookies.remove('token_admin', {path:'/', domain:$location.host()});
        try { $injector.get('socket').disconnect(); } catch (ex) {};
        try { $injector.get('Auth').systemProperties({clear:true}); } catch (ex) {};
      }
    },
    back: function (options) {
      options = options || {};
      var returnPath = this.returnPath || options.returnPath;
      delete this.returnPath;
      delete this.help;
      if (!returnPath) return;
      $location.path(returnPath);
      return returnPath;
    }
  };
}]);

app.factory('authInterceptor', ['$rootScope', '$q', '$cookies', 'LimaRedirect',
function ($rootScope, $q, $cookies, LimaRedirect) {
  return {
    // Add authorization token to headers
    request: function (config) {
      config.headers = config.headers || {};
      // if ($cookies.get('token')) {
      if ($cookies.get('token_admin')) {
          config.headers.Authorization = 'Bearer ' + $cookies.get('token_admin');
      }
      return config;
    },

    // Intercept 401s and redirect you to login
    responseError: function(response) {
      if(response.status === 401) {
        LimaRedirect.go({path:'/login', returnPath:true, help:'Login session has expired', clearCache:true});
        return $q.reject(response);
      } else {
        return $q.reject(response);
      }
    }
  };
}]);

app.directive('updateTitle', ['$state', 'Auth',
function ($state, Auth) {
  return {
    restrict: 'A',
    link: function (scope, element) {
      scope.$on('$stateChangeSuccess', function () {
        element.html($state.current.title);
      });
    }
  };
}]);

app.run(['$rootScope','$http', '$location', '$urlRouter', '$translate', '$q', 'Auth', 'LimaRedirect',
function ($rootScope, $http, $location, $urlRouter, $translate, $q, Auth, LimaRedirect) {
  function anonymousLogin(event, next) {
    event.preventDefault();
    $http.get('/api/v1/configurations/anonymous').success(function (anonymous) {
      if (!anonymous)
        return LimaRedirect.go({path:'/login', returnPath:$location.path(), help:'Please login', delay:0});
      Auth.login(anonymous.user).then(function () {
        $urlRouter.sync();
      }, function () {
        var help = anonymous.enabled ? 'Anonymous login failed' : 'Please login';
        LimaRedirect.go({path:'/login', returnPath:$location.path(), help:help, delay:0});
      });
    }).error(function (err) {
      LimaRedirect.go({path:'/login', returnPath:$location.path(), help:'Failed to get anonymous login params', delay:0});
    });

  }


  function routing(event, next, user) {
    if (!next.authenticate) return;

    //anonymous
    if (!Auth.isLoggedIn(true))
      return anonymousLogin(event, next);


    if (!Auth.isLoggedIn()) {
      event.preventDefault();
      return LimaRedirect.go({path:'/login', returnPath:$location.path(), help:'Please login', delay:0});
    }
    if (!next.functionName) return;
    if (!user.passwordExpired && Auth.hasFunction(next.functionName)) return;
    event.preventDefault();
    var options = {path:'/login', returnPath:$location.path(), help:'Forbidden', delay:0};
    if (user.passwordExpired) {
      options.path = '/settings';
      options.help = 'Password has expired';
    }
    LimaRedirect.go(options);
  }
  var onStateChangeStart = function (event, next) {
    event.preventDefault();
    $q.all([
      $translate('COMMON.OK')['catch'](angular.noop), // wait until language resource has been loaded
      Auth.systemProperties()['catch'](angular.noop) // wait until 'setting.style.id' is loaded then applied
    ]).then(function () {
      // then continue routing
      onStateChangeStart = function (event, next) {
        var user = Auth.getCurrentUser();
        if (user.hasOwnProperty('$promise') && user.hasOwnProperty('$resolved') && !user.$resolved) {
          event.preventDefault();
          user.$promise.then(function () { $urlRouter.sync(); }, function () { $urlRouter.sync(); });
        } else {
          routing(event, next, user);
        }
      };
      $urlRouter.sync();
    });
  };
  // Redirect to login if route requires auth and you're not logged in
  $rootScope.$on('$stateChangeStart', function (event, next) {
    onStateChangeStart(event, next);
  });
}]);
