'use strict';

var app = angular.module('limaApp');
var async = require('async');


app.controller('SystemCtrl', ['$scope', '$http', '$window','$translate', '$timeout', '$localStorage', 'Modal', 'Auth', 'socket','MediaedgeAdmin',
function ($scope, $http, $window, $translate, $timeout, $localStorage, Modal, Auth, socket, MediaedgeAdmin) {
  $scope.storage = (function () {
    var key = 'limaApp:SystemCtrl@' + Auth.getCurrentUser().name;
    var storage = $localStorage[key] || {};
    var store = function () {
      $localStorage[key] = storage;
    };
    var promise = null;
    $scope.$watch('storage', function () {
      if (promise) $timeout.cancel(promise);
      promise = $timeout(function () { store(); }, 10);
    }, true);
    $scope.$on('$destroy', function () {
      store();
    });
    return storage;
  })();

  $scope.user = {};

    // メディアエッジ社内向けページでは、registIdがSessionStrageに設定されていること（電話番号を暗号化して使用）
    MediaedgeAdmin.checkRegistId($http, function (can, tokenResult) {
      if (!can) {
        $window.location.href = "/admin";
      }
      else {
        $scope.encToken = encodeURIComponent(tokenResult.token);
        $scope.user = tokenResult.user;
        //$scope.getdbTimezone();
      }
    });



    function getCompanyInfoList(offset, limit, callback) {
      const url = $scope.regInfo.config.setting.protocol
        + "://"
        + $scope.regInfo.config.setting.domain
        + ":"
        + $scope.regInfo.config.setting.port
        + '/api/v1/company/list' + "?access_token=" + $scope.regInfo.token;

      $.ajax({
        type: 'GET',
        url: url,
        dataType: 'json',
        processData: false,
        data: $.param({
          offset: offset,
          limit: limit
        }),
        success: function (data) {
          callback(data);
        },
        error: function (data) {
          callback(null);
        }
      });

    }

    function getLoadBalance(companyId , callback) {
      $http.get('/api/v1/system/loadbalance/' + companyId).
      success(function(datasLB) {
        var sv = "";
        if (datasLB) {
          if (datasLB.length) {
            if (datasLB[0].server.length){
            sv = datasLB[0].server;
            }
          }
        }
        callback(sv);
      });
    }
    const URLJoin = (...args) =>
    args
      .join('/')
      .replace(/[\/]+/g, '/')
      .replace(/^(.+):\//, '$1://')
      .replace(/^file:/, 'file:/')
      .replace(/\/(\?|&|#[^!])/g, '$1')
      .replace(/\?/g, '&')
      .replace('&', '?');

    function getLBToken(option , callback) {

      var token = '';
      var param = {
        urlPath:URLJoin(option.server , '/auth/local'),
        method : 'POST',
        param : {
          loginAdmin:true,
          email: 'admin',
          password: 'Mediaedge4'
        }
      };

      const url = config.setting.protocol
        + "://"
        + config.setting.domain
        + ":"
        + config.setting.port
        + '/api/v1/system/message' + "?access_token=" + $scope.regInfo.token;

      $.ajax({
        type: 'POST',
        url: url,
        dataType: 'json',
        processData: false,
        contentType: 'application/json',
        data:JSON.stringify(param),
        success: function (datas) {
          token = datas.token;
          callback(null , token);

        },
        error: function (data) {
          callback("error" , "");
        }
      });
    }

    var getSystemInfo = function(option , callback) {
        if (option.server.length) {
          var param = {
            urlPath:URLJoin(option.server, '/api/v1/system') + '?access_token=' + option.token,
            method : 'GET',
            param : {}
          }

          const url = config.setting.protocol
            + "://"
            + config.setting.domain
            + ":"
            + config.setting.port
            + '/api/v1/system/message' + "?access_token=" + $scope.regInfo.token;

          $.ajax({
            type: 'POST',
            url: url,
            dataType: 'json',
            processData: false,
            contentType: 'application/json',
            data:JSON.stringify(param),
            success: function (datas) {
              callback(datas);
            },
            error: function (data) {
            }
          });
        } else {
          callback([]);
        }
    };

    var updateSystemInfo = function(option ,updateData ,  callback) {
      if (option.server.length) {
        var param = {
          urlPath:URLJoin(option.server, '/api/v1/system') + '?access_token=' + option.token,
          method : 'PUT',
          param : updateData
        }

        const url = config.setting.protocol
          + "://"
          + config.setting.domain
          + ":"
          + config.setting.port
          + '/api/v1/system/message' + "?access_token=" + $scope.regInfo.token;

        $.ajax({
          type: 'POST',
          url: url,
          dataType: 'json',
          processData: false,
          contentType: 'application/json',
          data:JSON.stringify(param),
          success: function (datas) {
            callback(datas);
          },
          error: function (data) {
          }
        });
      } else {
        callback([]);
      }
  };


    $scope.selectChange = function() {

    }

    function getInfo(infos, name){
      var find = _.find(infos , function(inf){
        return inf.name === name;
      })

      if (find){
        return find.value;
      }
      return '';
    }



    var config = [];
    $scope.regInfo = {};
    var regData = {};
    $scope.m_systemType = undefined; //AWSかオンプレ(1)か
    function getSystemType() {

      const url = $scope.regInfo.config.setting.protocol
      + "://"
      + $scope.regInfo.config.setting.domain
      + ":"
      + $scope.regInfo.config.setting.port
      + '/api/v1/configurations/RegistManageServer';  //token不要

      $.ajax({
        type: 'GET',
        url: url,
        contentType: 'application/json', // リクエストの Content-Type
        dataType: 'json',
        processData: false,
        success: function(data){
          if (data.system) {
            $scope.m_systemType = data.system.type;
            if ($scope.m_systemType) {
              if ($scope.selectPackage) {
                $scope.selectPackage.type = 1;
              }
            }
          }
        },
        error: function(data){
          //callback( false );
        }
      });
    }

    MediaedgeAdmin.getLoginInfo($http, function (err, info) {
      $scope.regInfo = info;
      getSystemType();
      $http.get('/api/v1/configurations/RegistManageServer').
      success(function(datas) {
        config = datas;

        $http({
          url: '/api/v1/company/',
          method: 'GET',
        })
        .success(function (result) {
          var offset = 0;
          var limit = result.length;
          getCompanyInfoList(offset, limit, function (data) {
            if (data != null) {
              $scope.companyList = data;

              $scope.arrLoadBSv = {datas : []};
              async.forEachOfSeries($scope.companyList , function(dt,index, cb) {
                getLoadBalance(dt.companyId , function(sv) {
                  console.log(index)
                  dt.server = sv;
                  cb(null)
                })
              }, function(err) {
                _.forEach($scope.companyList , function(dt) {
                  //if (dt.packageType)
                  if ((dt.server.length) &&  (dt.packageType > 0)){
                    var find = _.find($scope.arrLoadBSv.datas, function(lb) {
                        if (lb.server === dt.server) {
                          if (lb.packageType === dt.packageType) {
                            return true;
                          }
                        }
                    });
                    if (!find) {
                      var name = "";
                      if (dt.packageType === 0) {
                        name = $translate.instant('PACKAGE_0.base.DISPLAYNAME');
                      }
                      else if (dt.packageType === 1) {
                        name = $translate.instant('PACKAGE_MOTTO.base.DISPLAYNAME');
                      }

                      $scope.arrLoadBSv.datas.push({server: dt.server, packageType: dt.packageType, name : name , title : name + "(" + dt.server + ")"});
                    }
                  }
                })
                async.forEachOfSeries($scope.arrLoadBSv.datas ,  function(dt,index, cb2) {
                  getLBToken({server:dt.server} , function(err, token) {
                    if (token.length) {
                      dt.token = token;
                      getSystemInfo(dt , function(infos) {
                        dt.settings = {};
                        dt.settings.brandText = getInfo(infos , 'setting.brand.text') || '';
                        dt.settings.styleId = getInfo(infos , 'setting.style.id') || '';
                        $scope.settings.styleId = dt.settings.styleId;
                        dt.settings.movieMaxSize = Number(getInfo(infos , 'setting.system.movieMaxSize') || "500");
                        dt.settings.playlistContentCount = Number(getInfo(infos , 'setting.system.playlistContentCount') || "30");
                        var temp = getInfo(infos , 'setting.system.contentEncrypt') || "0";
                        dt.settings.contentEncryptEnable = temp !== "0" ? true : false;

                        //メッセージの情報取得
                        var arr = [];
                        _.forEach(infos , function(val) {
                          if (val.name.indexOf("infomation.message") !== -1) {
                            arr.push(JSON.parse(val.value));
                          }
                        });
                        if (arr.length) {
                          dt.m_InfoMessage = arr[0];
                        } else {
                          dt.m_InfoMessage = {};
                        }
                        dt.m_InfoMessage.selectType = $scope.arrInfoMassageType.datas[0];

                        cb2(null);

                      })
                    } else {
                      cb2(null);
                    }
                  })
                }, function(err) {
                  $timeout( function() {
                    $scope.arrLoadBSv.select = $scope.arrLoadBSv.datas[0];
                  })
                });
              });
            }
          });
        });
      });
    });


    $scope.close = function() {
      $window.location.href = "/company/support/list";

    }


  $translate('SYSTEM.MESSAGE_DEFAULT').then(function (s) { $scope.message = s; });

  $scope.settings = {};
  $scope.msg = {success:null, error:null};
  $scope.msg2 = {success:null, error:null};
  Auth.systemProperties().then(function (data) {
    $scope.settings.brandText = data['setting.brand.text'] || '';
    $scope.settings.styleId = data['setting.style.id'] || '';
    $scope.settings.movieMaxSize = Number(data['setting.system.movieMaxSize'] || "500");
    $scope.settings.playlistContentCount = Number(data['setting.system.playlistContentCount'] || "30");
    var temp = data['setting.system.contentEncrypt'] || "0";
    $scope.settings.contentEncryptEnable = temp !== "0" ? true : false;

    var infoMes = data['setting.infomation.message.1'] || '{}';


  });

  $scope.updateSetting = function() {
    Modal.confirm.yesno({
      html: _.escape($translate.instant('SYSTEM.SETTING.SUBMIT_CONFIRM_MESSAGE')),
      trustHtml: true
    }, function (yes) {
      if (!yes) return;
      var updateData = [];
      $scope.settings.styleId = $scope.style.id;
      updateData.push({name: 'setting.brand.text', value: $scope.arrLoadBSv.select.settings.brandText + ''});
      updateData.push({name: 'setting.style.id', value: $scope.settings.styleId + ''});

      updateData.push({name: 'setting.system.movieMaxSize', value: $scope.arrLoadBSv.select.settings.movieMaxSize + ''});
      updateData.push({name: 'setting.system.playlistContentCount', value: $scope.arrLoadBSv.select.settings.playlistContentCount + ''});
      updateData.push({name: 'setting.system.contentEncrypt', value: $scope.arrLoadBSv.select.settings.contentEncryptEnable ? "1" : "0"});

      updateSystemInfo($scope.arrLoadBSv.select , updateData , function(res) {

        $scope.msg.success = $translate.instant('SYSTEM.SETTING.UPDATED');
        $scope.msg.error = null;
      });

      // Auth.systemProperties({clear:true});

      // socket.connect().then(function () {
      //   return socket.call('v1:system:system-property:update', {data:updateData});
      // }).then(function (res) {
      //   $scope.style.selectedId = $scope.settings.styleId;
      //   $scope.msg.success = $translate.instant('SYSTEM.SETTING.UPDATED');
      //   $scope.msg.error = null;
      // }, function (err) {
      //   $scope.msg.success = null;
      //   $scope.msg.error = err;
      // });
    });
  };

  $scope.arrInfoMassageType = {
    datas:[
      {name:"info"}
    ]
  }

  $scope.updateSetting2 = function() {
    if (!$scope.arrLoadBSv.select) {
      return;
    }
    Modal.confirm.yesno({
      html: _.escape($translate.instant('SYSTEM.SETTING.SUBMIT_CONFIRM_MESSAGE')),
      trustHtml: true
    }, function (yes) {
      if (!yes) return;
      var updateData = [];

      var arrMes = [];
      if ($scope.arrLoadBSv.select.m_InfoMessage.message.length) {
        arrMes = $scope.arrLoadBSv.select.m_InfoMessage.message.split("\n"); //LF
      }
      var obj = {};
      obj.type = $scope.arrLoadBSv.select.m_InfoMessage.selectType.name;
      obj.message = arrMes;
      obj.url =  $scope.arrLoadBSv.select.m_InfoMessage.url;

      updateData.push({name: 'setting.infomation.message.1', value:JSON.stringify(obj,null,2) });

      updateSystemInfo($scope.arrLoadBSv.select , updateData , function(res) {

        $scope.msg2.success = $translate.instant('SYSTEM.SETTING.UPDATED');
        $scope.msg2.error = null;
      });
    });
  }

  $scope.style = (function () {
    var style = {enabled:false};
    var $styles = angular.element('.lima-bootstrap-style');
    if (!$styles.length) return style;
    function applyStyle(styleId) {
      if (!styleId) return;
      styleId = '#' + styleId;
      if (!$styles.filter(styleId).length) return;
      $styles.filter(styleId).prop('disabled', false);// .attr('rel', 'stylesheet');
      $styles.not(styleId).prop('disabled', true);// .attr('rel', 'alternate stylesheet');
    }
    $scope.$watch('style.id', function () {
      applyStyle(style.id);
    });
    $scope.$on('$destroy', function () {
      applyStyle(style.selectedId);
    });
    var enabled = $styles.filter(":enabled");
    if (enabled.length) {
      style.selectedId = style.id = enabled[0].id;
    }
    style.enabled = true;
    return style;
  })();

}]);
