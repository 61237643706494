'use strict';

var app = angular.module('limaApp');


app.controller('CompanyEditOptionCtrl', ['$scope', 'socket', 'Auth','$http', '$timeout', '$location','$cookies','$uibModalInstance','CompanyInfo','MediaedgeAdmin',
function($scope, socket, Auth, $http, $timeout, $location, $cookieStore,$modalInstance,CompanyInfo, MediaedgeAdmin) {

  //- ===========================
  //-  オプションテーブル関連。pugと連携するものはここに定義
  const _selectOptionLibrary = require('../_selectOptionLibrary');
  $scope.optlib = new _selectOptionLibrary($scope);
  $scope.optlib.MediaedgeAdmin = MediaedgeAdmin;
  $scope.optionServiceList= undefined;
  $scope.totalPrices = 0;       // 費用合計
  $scope.initialPrices = $scope.optlib.getInitialPrices();  // 契約手数料
  $scope.changeDeviceChoise = function (optName, optChecked) { return $scope.optlib.changeDeviceChoise($scope, optName, optChecked);  }
  $scope.getPackageType = function() { return CompanyInfo.data.packageType; }
  //- ===========================
  $scope.optlib.getListOfOptionServiceFromCompanyId( $scope, $http, $timeout, 
                CompanyInfo.token, 
                CompanyInfo.data.companyId, 
                CompanyInfo.data.packageType, function() {
  });

  $timeout(function() {
    $scope.companyInfo = CompanyInfo.data;

  });
  $scope.cancel = function() {
    //$modalInstance.dismiss('cancel');

  }
  $scope.apply = function () {

    var postdata = {
      options : $scope.optionServiceList,
      companyId: CompanyInfo.data.companyId
    };

    MediaedgeAdmin.getLoginInfo($http,function(err ,info) {
      $scope.regInfo = info;

      const url = $scope.regInfo.config.setting.protocol
      + "://"
      + $scope.regInfo.config.setting.domain
      + ":"
      + $scope.regInfo.config.setting.port
      + '/api/v1/company/options/update/' + CompanyInfo.data.companyId + "?access_token=" + $scope.regInfo.token;

      $.ajax({
        type: 'PUT',
        url: url,
        contentType: 'application/json', // リクエストの Content-Type
        dataType: 'json',
        processData: false,
        data: JSON.stringify(postdata),
        success: function(data){
          $modalInstance.dismiss('done');;
        },
        error: function(data){
//          callback(null);
        }
    });
    });
  }
  $scope.cancel = function() {
    $modalInstance.dismiss('cancel');
  }

}]);
