'use strict';

var app = angular.module('limaApp');

/*
*/
app.controller('AdminLoginCtl', ['$scope', '$translate', '$http', '$window', '$timeout', 'MediaedgeAdmin', 'Modal', 'Auth',
function ($scope, $translate, $http, $window, $timeout,MediaedgeAdmin, Modal, Auth ) {

  $scope.pageType = 'adminLoginInfo';
  $scope.terms_ok = false;
  $scope.encToken= "";
  $scope.user = {
    account: "",
    password: ""
  };
  const storage = sessionStorage;
  storage.clear();

  $scope.cancel = function() {
    //window.history.back();
  }

  $scope.canCompanyListPage = function() {
    if ( $scope.user.account.length == 0 ) {
      return false;
    }
    if ( $scope.user.password.length == 0 ) {
      return false;
    }
    return true;
  }


  $scope.toSupportPage = function() {
    if ( $scope.user.account !== 'admin' || $scope.user.password !== 'Mediaedge4' ) {
      Modal.confirm.message({
        title:$translate.instant('COMMON.CONFIRM'),
        html:"アカウントもしくはパスワードが違います",
        modalClass:'modal-warning'
      });
      return false;
    }
    MediaedgeAdmin.setRegistId($http, function(tokenResult, tokenThisResule ){
      $scope.encToken= encodeURIComponent(tokenResult.token);
      $scope.user = tokenResult.user
      Auth.login({
        loginAdmin: true,
        companyLoginId:  null,
        email: 'admin',
        password: 'Mediaedge4'
      })
      .then(function() {
        $timeout(function(){
          // メディアエッジ社内向けページでは、registIdがSessionStorageに設定されていること（電話番号を暗号化して使用）
          $window.location.href="/company/support/list";
        });
      });
    });
  }

  $scope.toCompanyListPage = function() {
    if ( $scope.user.account !== 'admin' || $scope.user.password !== 'Mediaedge4' ) {
      Modal.confirm.message({
        title:$translate.instant('COMMON.CONFIRM'),
        html:"アカウントもしくはパスワードが違います",
        modalClass:'modal-warning'
      });
      return false;
    }

    MediaedgeAdmin.setRegistId($http, function(tokenResult, tokenThisResule ){
      $scope.encToken= encodeURIComponent(tokenResult.token);
      $scope.user = tokenResult.user
      Auth.login({
        loginAdmin: true,
        companyLoginId:  null,
        email: 'admin',
        password: 'Mediaedge4'
      })
      .then(function() {
        $timeout(function(){
          // メディアエッジ社内向けページでは、registIdがSessionStorageに設定されていること（電話番号を暗号化して使用）
          $window.location.href="/company/list";
        });
      });
    })
  }
}]);
