'use strict';

var app = angular.module('limaApp');


app.controller('CompanySupportSettingCtrl', ['$scope', '$translate','socket', 'Auth','$http', '$timeout', '$location','$cookies','$uibModalInstance','$uibModal','Modal','CompanyInfo','MediaedgeAdmin',
function($scope, $translate, socket, Auth, $http, $timeout, $location, $cookieStore,$modalInstance,$modal,Modal, CompanyInfo,MediaedgeAdmin) {

  $timeout(function() {
    $scope.companyInfo = CompanyInfo.data;

  });
  $scope.cancel = function() {
    //$modalInstance.dismiss('cancel');

  }
  $scope.apply = function () {
    $modalInstance.close({name:$scope.formInput.inputname});
    $modalInstance.dismiss('done');
    //$dismiss();
  }
  $scope.cancel = function() {
    $modalInstance.dismiss('cancel');

  }
  $scope.StringToDate = function(datestring){
    return new Date(datestring);
  }
  $scope.formatDate = function (date, format) {
    if (!format) format = 'YYYY-MM-DD hh:mm:ss.SSS';
    format = format.replace(/YYYY/g, date.getFullYear());
    format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
    format = format.replace(/DD/g, ('0' + date.getDate()).slice(-2));
    format = format.replace(/hh/g, ('0' + date.getHours()).slice(-2));
    format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
    format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
    if (format.match(/S/g)) {
      var milliSeconds = ('00' + date.getMilliseconds()).slice(-3);
      var length = format.match(/S/g).length;
      for (var i = 0; i < length; i++) format = format.replace(/S/, milliSeconds.substring(i, i + 1));
    }
    return format;
  };

  var m_serverLB = '';
  var m_serverLB_token = '';
  function getLBToken(callback) {

    var param = {
      urlPath:URLJoin(m_serverLB , '/auth/local'),
      method : 'POST',
      param : {
        loginAdmin:true,
        email: 'admin',
        password: 'Mediaedge4'
      }
    };

    const url = config.setting.protocol
      + "://"
      + config.setting.domain
      + ":"
      + config.setting.port
      + '/api/v1/system/message' + "?access_token=" + $scope.regInfo.token;

    $.ajax({
      type: 'POST',
      url: url,
      dataType: 'json',
      processData: false,
      contentType: 'application/json',
      data:JSON.stringify(param),
      success: function (datas) {
        m_serverLB_token = datas.token;
        callback(null);

      },
      error: function (data) {
      }
    });
  }

  function getLoadBalance(callback) {
    $http.get('/api/v1/system/loadbalance/' + CompanyInfo.data.companyId).
    success(function(datasLB) {
      if (datasLB) {
        if (datasLB.length) {
          if (datasLB[0].server.length){
            m_serverLB = datasLB[0].server;
            getLBToken(function(err){
              callback(null);
            });
          } else {
            callback(null);
          }
        } else {
          callback(null);
        }
      } else {
        callback(null);
      }
    });
  }

  $scope.devicelists = [];
  var config = [];
  $scope.regInfo = {};
  var regData = {};
  var getConfigration = function() {

    $scope.regInfo = null;
    MediaedgeAdmin.getLoginInfo($http, function (err, info) {
      $scope.regInfo = info;
      $http.get('/api/v1/configurations/RegistManageServer').
      success(function(datas) {
        config = datas;

        getRegistConf(function(reg) {
          regData = reg;
          getSetting();
        });
      });

    });

  }

  $scope.checkedlist = [];
  $scope.UpdateCheckedList = (function() {
    $scope.checkedlist = _.filter($scope.devicelists, function(list){return list.checked;});
  });

  $scope.controlLists = [];
  var getControlLists = function() {
    $http.get('/api/v1/controls').
    success(function(datas) {
      $scope.controlLists.items = datas.controls;
      _.forEach($scope.controlLists.items, function(dt){
        dt.displayName = dt.controlName;
      })
      $scope.controlLists.selected = $scope.controlLists.items[0];
    });

  };
  getControlLists();

  $scope.doControlDevice = function(){
    Modal.confirm.yesno({
      title: $translate.instant('COMMON.CONFIRM'),
      html: "デバイスコントロール(" + $scope.controlLists.selected.displayName + ")を行います。よろしいですか？",//$translate.instant('USER.USER_DELETE_OWN_MESSAGE'),
      btnClassYes: 'btn-default',
      btnCaptionYes: $translate.instant('COMMON.YES'),
      btnClassNo: 'btn-danger',
      btnCaptionNo: $translate.instant('COMMON.NO'),
      modalClass: 'modal-danger'
    }, function (yes) {
      if (!yes) {
        return;
      }

      var arrParam = [];
      var  selected = _.filter($scope.devicelists, function(list){return list.checked;});
      _.forEach(selected, function(sel){
        if (sel.deviceId){
          var param = {
            control:$scope.controlLists.selected,
            deviceId:sel.deviceId
          }
          arrParam.push(param);
        }
      });

      if (m_serverLB.length) {
        var param = {
          urlPath:URLJoin(m_serverLB, '/api/v1/device/control') + '?access_token=' + m_serverLB_token,
          method : 'POST',
          param : {data:arrParam}
        }

        const url = config.setting.protocol
          + "://"
          + config.setting.domain
          + ":"
          + config.setting.port
          + '/api/v1/system/message' + "?access_token=" + $scope.regInfo.token;

        $.ajax({
          type: 'POST',
          url: url,
          dataType: 'json',
          processData: false,
          contentType: 'application/json',
          data:JSON.stringify(param),
          success: function (datas) {
          },
          error: function (data) {
            Modal.confirm.message({
              title:$translate.instant('COMMON.CONFIRM'),
              html:'操作できませんでした',
              modalClass:'modal-warning'
            });
          }
        });
      } else {
        $http.post('/api/v1/device/control', {data:arrParam}).
        success(function(err) {
        });
      }


//      socket.emit('lima:call-request:v1:device:control',arrParam);

    });
  }

  var getSystemInfo = function(callback) {
    if (m_serverLB.length) {
      var param = {
        urlPath:URLJoin(m_serverLB, '/api/v1/system') + '?access_token=' + m_serverLB_token,
        method : 'GET',
        param : {}
      }

      const url = config.setting.protocol
        + "://"
        + config.setting.domain
        + ":"
        + config.setting.port
        + '/api/v1/system/message' + "?access_token=" + $scope.regInfo.token;

      $.ajax({
        type: 'POST',
        url: url,
        dataType: 'json',
        processData: false,
        contentType: 'application/json',
        data:JSON.stringify(param),
        success: function (datas) {
          callback(datas);
        },
        error: function (data) {
        }
      });
    } else {
      callback([]);
    }
};


  $scope.settings = [];

  function getSettingValue(datas){
    //初期化
    $scope.settings['setting.server'] = {};
    $scope.settings['setting.ui'] = {};

    _.find(datas, function(dt) {
      if (dt.name === "setting.server") {
        var obj = dt.value;
        $scope.settings['setting.server'] = obj;
      };
      if (dt.name === "setting.ui") {
        var obj = dt.value;
        $scope.settings['setting.ui'] = obj;
      };
    });

    //設定がなかったらシステムデフォルトから持ってくる
    if (_.isUndefined($scope.settings['setting.server'].contentEncryptEnable)){
      $scope.settings['setting.server'].contentEncryptEnable = m_sysInfo.contentEncryptEnable
    }
    if (_.isUndefined($scope.settings['setting.ui'].playlistContentCount)){
      $scope.settings['setting.ui'].playlistContentCount = m_sysInfo.playlistContentCount
    }
    if (_.isUndefined($scope.settings['setting.ui'].movieMaxSize)){
      $scope.settings['setting.ui'].movieMaxSize = m_sysInfo.movieMaxSize
    }

  }

  function updateSettingValue(){
    var datas = [];
    datas.push(
      {
        name : 'setting.server',
        value : $scope.settings['setting.server']
      }
    );
    datas.push(
      {
        name : 'setting.ui',
        value : $scope.settings['setting.ui']
      }
    );
      return datas;
  }

  function getInfo(infos, name){
    var find = _.find(infos , function(inf){
      return inf.name === name;
    })

    if (find){
      return find.value;
    }
    return '';
  }

  function getesysSetting(callback){
    var param = {
      urlPath:URLJoin(m_serverLB, '/api/v1/content/extra/esys/line') + '?companyId=' + CompanyInfo.data.companyId + '&access_token=' + m_serverLB_token,
      method : 'GET',
      param : {}
    }

    const url = config.setting.protocol
      + "://"
      + config.setting.domain
      + ":"
      + config.setting.port
      + '/api/v1/system/message' + "?access_token=" + $scope.regInfo.token;

    $.ajax({
      type: 'POST',
      url: url,
      dataType: 'json',
      processData: false,
      contentType: 'application/json',
      data:JSON.stringify(param),
      success: function (datas) {
        $timeout(function(){
          $scope.settings['esys.line'] = datas;
        });
        callback();
      },
      error: function (data) {
        callback();
      }
    });

  }

  function updateesysSetting(callback){
    if (!$scope.settings['esys.line']){
        callback(null);
        return;
    }
    var param = {
      urlPath:URLJoin(m_serverLB, '/api/v1/content/extra/esys/line') + '?companyId=' + CompanyInfo.data.companyId + '&access_token=' + m_serverLB_token,
      method : 'PUT',
      param : $scope.settings['esys.line'].contractInfo
    }

    const url = config.setting.protocol
      + "://"
      + config.setting.domain
      + ":"
      + config.setting.port
      + '/api/v1/system/message' + "?access_token=" + $scope.regInfo.token;

    $.ajax({
      type: 'POST',
      url: url,
      dataType: 'json',
      processData: false,
      contentType: 'application/json',
      data:JSON.stringify(param),
      success: function (datas) {
        $timeout(function(){
          //$scope.settings['esys.line'] = datas;
        });
        callback();
      },
      error: function (data) {
        callback();
      }
    });

  }
  $scope.m_systemType = undefined; //AWSかオンプレ(1)か
  function getSystemType() {

    const url = $scope.regInfo.config.setting.protocol
    + "://"
    + $scope.regInfo.config.setting.domain
    + ":"
    + $scope.regInfo.config.setting.port
    + '/api/v1/configurations/RegistManageServer';  //token不要

    $.ajax({
      type: 'GET',
      url: url,
      contentType: 'application/json', // リクエストの Content-Type
      dataType: 'json',
      processData: false,
      success: function(data){
        if (data.system) {
          $scope.m_systemType = data.system.type;
          if ($scope.m_systemType) {
            if ($scope.selectPackage) {
              $scope.selectPackage.type = 1;
            }
          }
        }
      },
      error: function(data){
        //callback( false );
      }
    });
  }


  var m_sysInfo = {}; //システム設定
  var getSetting = function() {
    getLoadBalance(function(err){
      if (m_serverLB.length) {
        getSystemType();
        getSystemInfo(function(infos) {
          m_sysInfo = {};
          m_sysInfo.movieMaxSize = Number(getInfo(infos , 'setting.system.movieMaxSize') || "500");
          m_sysInfo.playlistContentCount = Number(getInfo(infos , 'setting.system.playlistContentCount') || "30");
          var temp = getInfo(infos , 'setting.system.contentEncrypt') || "0";
          m_sysInfo.contentEncryptEnable = temp !== "0" ? true : false;

          var param = {
            urlPath:URLJoin(m_serverLB, '/api/v1/setting') + '?companyId=' + CompanyInfo.data.companyId + '&access_token=' + m_serverLB_token,
            method : 'GET',
            param : {}
          }

          const url = config.setting.protocol
            + "://"
            + config.setting.domain
            + ":"
            + config.setting.port
            + '/api/v1/system/message' + "?access_token=" + $scope.regInfo.token;

          $.ajax({
            type: 'POST',
            url: url,
            dataType: 'json',
            processData: false,
            contentType: 'application/json',
            data:JSON.stringify(param),
            success: function (datas) {
              $timeout(function(){
                getSettingValue(datas);

                //LINE
                getesysSetting(function(){

                });


              });
            },
            error: function (data) {
            }
          });
        })
      } else {
        $http.get('/api/v1/setting?companyId=' + CompanyInfo.data.companyId + '&access_token=' + CompanyInfo.token).
        success(function(datas) {
          getSettingValue(datas);
        });
      }
    });
  };
  getConfigration();

  $scope.updateSetting = function() {
    var arrSettings = updateSettingValue($scope.settings);
    getLoadBalance(function(err){
      if (m_serverLB.length) {
        var param = {
          urlPath:URLJoin(m_serverLB, '/api/v1/setting') + '?&access_token=' + m_serverLB_token,
          method : 'POST',
          param : {
            companyId :CompanyInfo.data.companyId,
            datas : arrSettings
          }
        }

        const url = config.setting.protocol
          + "://"
          + config.setting.domain
          + ":"
          + config.setting.port
          + '/api/v1/system/message' + "?access_token=" + $scope.regInfo.token;

        $.ajax({
          type: 'POST',
          url: url,
          dataType: 'json',
          processData: false,
          contentType: 'application/json',
          data:JSON.stringify(param),
          success: function (datas) {
            updateesysSetting(function() {
              Modal.confirm.message({
                title:$translate.instant('COMMON.CONFIRM'),
                html:'適用しました。',
                modalClass:'modal-primary'
              });
            })
          },
          error: function (data) {
          }
        });
      } else {
        $http.post('/api/v1/setting?companyId=' + CompanyInfo.data.companyId + '&access_token=' + CompanyInfo.token , updateSettingValue($scope.settings)).
        success(function(datas) {
          Modal.confirm.message({
            title:$translate.instant('COMMON.CONFIRM'),
            html:'適用しました。',
            modalClass:'modal-primary'
          });
        });
      }
    });
  };

  function getRegistConf(callback) {
    MediaedgeAdmin.getLoginInfo($http,function(err ,info) {
      $scope.regInfo = info;

      const url = $scope.regInfo.config.setting.protocol
      + "://"
      + $scope.regInfo.config.setting.domain
      + ":"
      + $scope.regInfo.config.setting.port
      + '/api/v1/configurations/RegistManageServer';// + "?access_token=" + $scope.regInfo.token;

      $.ajax({
        type: 'GET',
        url: url,
        contentType: 'application/json', // リクエストの Content-Type
        dataType: 'json',
        processData: false,
//        data: JSON.stringify(postdata),
        success: function(data){
          callback(data);
        },
        error: function(data){
          callback(null);
        }
      });
    });
  }

  const URLJoin = (...args) =>
  args
    .join('/')
    .replace(/[\/]+/g, '/')
    .replace(/^(.+):\//, '$1://')
    .replace(/^file:/, 'file:/')
    .replace(/\/(\?|&|#[^!])/g, '$1')
    .replace(/\?/g, '&')
    .replace('&', '?');


}]);
